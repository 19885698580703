.hello-button{
	cursor: pointer;
}
.hello{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: $modalZIndex;
	background: $backgroundColor;
	background: var(--background-color);
	color: $brandColor;
	color: var(--brand-color);
	opacity: 1;
	transition: opacity 250ms ease-in-out;
	span{
		display: inline-block;
		vertical-align: middle;
	}
	&.hello-full{
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: $modalZIndex;
		font-size:20em;
		text-align: center;
		&:before{
			content:'';
			vertical-align: middle;
			height: 100%;
			width: 1px;
			display: inline-block;
			margin-right: -1px;
		}
	}
	&.hello-creature{
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: $modalZIndex;
		font-size: 4rem;
		text-align: center;
		&:before{
			content:'';
			vertical-align: middle;
			height: 100%;
			width: 1px;
			display: inline-block;
			margin-right: -1px;
		}
	}
	&.hello-leaving{
		opacity: 0;
		pointer-events: none;
	}
}
@import "./ball";
@import "./hydrate";

