@font-face {
  font-family: 'Museo Sans 100';
  src: url('/fonts/museo-sans/museo-sans-100.eot');
  src:
    url('/fonts/museo-sans/museo-sans-100.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-100.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-100.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-100.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-100.svg#museo-sans-100') format('svg');
}
@font-face {
  font-family: 'Museo Sans 100 Italic';
  src: url('/fonts/museo-sans/museo-sans-100-italic.eot');
  src:
    url('/fonts/museo-sans/museo-sans-100-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-100-italic.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-100-italic.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-100-italic.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-100-italic.svg#museo-sans-100-italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
  font-family: 'Museo Sans 300';
  src: url('/fonts/museo-sans/museo-sans-300.eot');
  src:
    url('/fonts/museo-sans/museo-sans-300.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-300.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-300.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-300.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-300.svg#museo-sans-300') format('svg');
}
@font-face {
  font-family: 'Museo Sans 500';
  src: url('/fonts/museo-sans/museo-sans-500.eot');
  src:
    url('/fonts/museo-sans/museo-sans-500.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-500.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-500.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-500.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-500.svg#museo-sans-500') format('svg');
}
@font-face {
  font-family: 'Museo Sans 500 Italic';
  src: url('/fonts/museo-sans/museo-sans-500-italic.eot');
  src:
    url('/fonts/museo-sans/museo-sans-500-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-500-italic.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-500-italic.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-500-italic.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-500-italic.svg#museo-sans-500-italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
  font-family: 'Museo Sans 700';
  src: url('/fonts/museo-sans/museo-sans-700.eot');
  src:
    url('/fonts/museo-sans/museo-sans-700.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-700.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-700.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-700.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-700.svg#museo-sans-700') format('svg');
}
@font-face {
  font-family: 'Museo Sans 900';
  src: url('/fonts/museo-sans/museo-sans-900.eot');
  src:
    url('/fonts/museo-sans/museo-sans-900.eot?#iefix') format('embedded-opentype'),
    url('/fonts/museo-sans/museo-sans-900.woff2') format('woff2'),
    url('/fonts/museo-sans/museo-sans-900.woff') format('woff'),
    url('/fonts/museo-sans/museo-sans-900.ttf') format('truetype'),
    url('/fonts/museo-sans/museo-sans-900.svg#museo-sans-900') format('svg');
}