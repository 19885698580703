.rich_text {
	max-width: 45em;
	// don't show the label on the source button
	.cke_button__source_label {
		display: none;
	}

	&.locked {
		border: none;

		.cke {
			display: none;
		}

		.charCounter {
			display: none;
		}
	}
	&.short_text{
		.cke_chrome{
			border:none;
		}
	}
	.cke_wysiwyg_frame{
		background: var(--background-color);
	}
}
