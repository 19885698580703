button {
	background: #{$backgroundColor}; //ie fallback
	background: var(--background-color);
	border: 2px solid;
	border-color: #{$actionColor}; // IE-fallback;
	border-color: var(--action-color);
	padding: #{$halfPadding}; // IE fallback
	padding: var(--half-padding);
	color: #{$textColor}; // IE-fallback;
	color: var(--text-color);
	vertical-align: middle;
	cursor: pointer;
	text-align: center;
	display: inline-block;
	&:hover, &.selected {
		background: #{$actionColor}; // IE-fallback;
		background: var(--action-color);
	}
	&.action{
		background: $actionColor;
		background: var(--action-color);
		color: #{$textColor}; // IE fallback;
		color: var(--text-color);
		padding: #{$halfPadding} #{$fullPadding}; // IE fallback
		padding: var(--half-padding) var(--full-padding);
		&:active, &:hover{
			background: darken($actionColor, 10);
		}
	}
	&.invert{
		background: #{$backgroundColor}; // IE fallback
		background: var(--background-color);
		color: #{$textColor}; // IE fallback;
		color: var(--text-color);
		&:hover{
			background: #{$actionColor}; // IE fallback
			background: var(--action-color);
		}
	}
	&.cancel{
		&.link {
			color: #{$brandColor}; // IE fallback;
			color: var(--brand-color);
			font-size: #{$semiFontSize}; // IE fallback;
			font-size: var(--semi-font-size);
			&:hover {
				color: #{$brandColor}; // IE fallback;
			color: var(--brand-color);
				background: transparent;
				text-decoration: underline;
			}
		}
	}
	&:disabled{
		background: #{$accentColor}; // IE fallback
		background: var(--accent-color);
		color: #{$textAccentColor}; // IE fallback
		color: var(--text-accent-color);
		border: 2px solid;
		border-color: #{$accentColor}; // IE fallback
		border-color: var(--accent-color);
		&:hover {
			background: #{$accentColor}; // IE fallback
			background: var(--accent-color);
			color: #{$textAccentColor}; // IE fallback
			color: var(--text-accent-color);
			border: 2px solid;
			border-color: #{$accentColor}; // IE fallback
			border-color: var(--accent-color);
		}
	}
	&.link {
		color: #{$brandColor}; // IE-fallback;
		color: var(--brand-color);
		background: transparent;
		text-decoration: none;
		border: none;
		&:hover {
			text-decoration: underline;
		}
	}
}