.setup {
	.side-nav v-pane-header{
		max-width: none;
		&:hover{
			text-decoration: none;
		}
	}
	label {
		cursor: pointer;
	}
	em {
		font-size: $iconFontSize;
		color: $textAccentColor; // IE fallback
		color: var(--text-accent-color);
		margin-left: $fullMargin; // IE fallback
		margin-left: var(--full-margin);
	}
	.side-nav-sub-title{
		text-indent: 3rem;
	}
}
