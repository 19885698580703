.form {
	box-sizing: border-box;
	width: 100%;
	padding: #{$doublePadding}; // IE fallback
	padding: var(--double-padding);
}

.field-wrapper, .form-wrapper{
	line-height: #{$baseLineHeight};
	padding: #{$quarterPadding} #{$halfPadding} #{$quarterPadding} #{$fullPadding}; // IE fallback
	padding: var(--quarter-padding) var(--half-padding) var(--quarter-padding) var(--full-padding);
	margin-bottom: #{$halfMargin}; // IE fallback;
	margin-bottom: var(--half-margin);
	position: relative;
	width:100%;
}
.form-wrapper{
	overflow: auto;
}
.field-wrapper {
	padding-left: #{$fieldPaddingLeft};
	&.ng-enter, &.ng-leave {
		-webkit-transition: opacity 0.2s ease;
		transition: opacity 0.2s ease;
	}
	&.ng-enter.ng-enter-active {
		opacity: 1;
	}
	&.ng-enter, &.ng-leave-active {
		opacity: 0;
	}
	&:not(.label) > span {
		white-space: normal;
	}
	&:empty {
		display: none;
	}
	.icons {
		display: none;
	}
	&:hover .icons {
		display: inline;
	}
	&.changed {
		background: var(--changes-color);
		background: #{$changesColor};
	}
	.field-label {
		display: inline-block;
		position: relative;
		// fix for double-wrapped span tags not having space between adjacent spans
		> span > span {
			margin: 0 #{$quarterMargin}; //IE Fallback
			margin: 0 var(--quarter-margin);
		}
	}
	.field-wrapper {
		padding-left: #{$fullPadding};
		padding-left: var(--full-padding);
	}
	input {
		background: #{$backgroundColor}; // IE Fallback
		background: var(--background-color);
	}
	&.ng-invalid:before {
		left: #{$fieldPaddingLeft + -$iconSize * $iconFontVsNormalFontRatio};
	}
}

#{$fieldValueItems} {
	padding: 0;
	border: 0;
	border-bottom: 2px dotted;
	border-bottom-color: #{$brandColor}; // IE-fallback;
	border-bottom-color: var(--brand-color);
	color: #{$brandColor}; // IE-fallback;
	color: var(--brand-color);
	white-space: nowrap;
	vertical-align: baseline;
	display: inline-block;
	outline: none;
	cursor: pointer;
	border-radius: 0;
	box-sizing: content-box;
	font-size: #{$normalFontSize}; // IE fallback
	font-size: var(--normal-font-size);
	font-weight: normal;
	text-transform: none;
	position: relative;
	min-height: 1.5rem;
	&.disabled, &[disabled]{
		border-bottom-color: #{$accentColor}; // IE fallback
		border-bottom-color: var(--accent-color);
		color: #{$textColor}; // IE fallback
		color: var(--text-color);
		outline: none;
		cursor: auto;
		&:hover:after,
		&:hover:before {
			display: none;
		}
		&:focus{
			outline:none;
		}
	}
	&.placeholder-shown {
		border-bottom-color: #{$brandColor}; // IE-fallback;
		border-bottom-color: var(--brand-color);
		font-style: italic;
	}
}
.field-wrapper.richtext{
	margin-top: #{$quarterMargin};
	margin-top: var(--quarter-margin);
}
