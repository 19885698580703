div.sidebar {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  top: #{$headerHeight};
  right:0;
  bottom: 0;
  z-index: #{$sidebarZIndex};
  width: 48rem;
  background: #{$backgroundColor}; // IE fallback
  background: var(--background-color);
  border-left: 1px solid;
  border-left-color: #{$accentColor}; // IE fallback
  border-left-color: var(--accent-color);
  border: 1px solid $brandColor;
  margin-right: -48rem;
  -webkit-transition: 0.3s ease-in margin-right;
  transition: 0.3s ease-in margin-right;
  &.open{
    margin-right: 0;
  }
  .sidebar-container {
    position: relative;
    height: 100%;
  }
  .sidebar-content {
    padding: #{$halfPadding}; // IE fallback
    padding: var(--half-padding);
    position: relative;
    height: 100%;
  }
  .sidebar-title{
    cursor: pointer;
    position: absolute;
    right: #{$fullPadding}; // IE fallback
    right: var(--full-padding);
    top: #{$fullPadding}; // IE fallback
    top: var(--full-padding);
    z-index: 1;
  }
 .fa-times {  
    color: #{$brandColor}; // IE fallback;
    color: var(--brand-color);
    float: right;
    font-size: 30px;
  }
  .sidebar-content{
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: stretch;
    img{
			max-width: 100%;
		}
  }
}
