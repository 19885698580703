.edit-icon,
.delete-icon {
	opacity: 0.33;
	cursor: pointer;
	&:hover{
		opacity: 0.66;
	}
}
// todo: should be able to kill this entirely
.icon {
	color: #{$textAccentColor}; // IE fallback
	color: var(--text-accent-color);
	&:hover {
		color: #{$textColor}; // IE fallback
		color: var(--text-color);
	}
}
