@charset "UTF-8";
@import "../../../node_modules/mathsass/dist/_math";
@import "shared/normalize";
@import "shared/colors";
@import "shared/variables";
@import "shared/fonts";
@import "shared/utility";
@import "shared/themes";
@import "shared/responsive";

@import "components/buttons";
@import "components/comment_box";
@import "components/custom_title";
//@import "components/editor";
@import "components/encyclopedia";
@import "components/errors";
@import "components/extra_menu";
@import "components/header";
@import "components/icons";
@import "components/logo";
@import "components/layout";
@import "components/page";
@import "components/plain_form";
@import "components/product_nav";
@import "components/progress";
@import "components/publishing";
@import "components/setup";
@import "components/side_nav";
@import "components/sidebar";
@import "components/switch";
@import "components/tabs";
@import "components/table";
@import "components/title";
@import "components/validation";

// shared field stuff
@import "components/fields/form";
@import "components/fields/history";
@import "components/fields/alert";
@import "components/fields/aside";
@import "components/fields/panel";

// field types
@import "components/fields/alert";
@import "components/fields/aside";
@import "components/fields/collection";
@import "components/fields/date";
@import "components/fields/dropdown";
@import "components/fields/eligibility";
@import "components/fields/file";
@import "components/fields/group";
@import "components/fields/picker";
@import "components/fields/richtext";
@import "components/fields/text";
@import "components/fields/tier";
@import "components/fields/toggle";

@import "components/hello/base";

@import "components/modals/loader";
@import "components/modals/modal";
@import "components/modals/setup_wizard";

@import "pages/configurations";
@import "pages/customers";
@import "pages/login";
@import "pages/module_usage";

/*
we'd like to just import these as sass
but ruby does a bad job importing css files...
foolishly converting them to css url imports
https://github.com/sass/sass/issues/556
so, these are currently included via <link>s in index.template.html, for now

@import "../bower_components/ng-tags-input/ng-tags-input.min.css";
@import "../bower_components/pickadate/lib/compressed/themes/default.css";
@import "../bower_components/pickadate/lib/compressed/themes/default.date.css";
*/
// this one actually is sass :D
@import "../bower_components/v-accordion/src/vAccordion/styles/vAccordion";

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.ng-hide{ // make stuff with ng-hide hidden even before angular has loaded
	display:none;
}
