.field-file{
	input.field-file-input {
		display: none !important;
	}
	.field-file-input-label {
		width: auto;
	}
	.field-file-input-label.disabled{
		display:inline-block;
		border-bottom-color: #{$gray};
		color: #{$black};
		cursor: auto;
	}
	&.polyfilled{
		.field-file-input-label{
			display:none;
		}
		.field-file-input-label.disabled{
			display:inline-block;
		}
	}
	.fa {
		cursor: pointer;
	}
	a {
		text-decoration: none;
	}
	.polyfilledInput{
		&.disabled{
			display:none;
		}
	}
}