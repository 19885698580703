.configurations, .customers{
	padding: #{$fullPadding}; // IE fallback
	padding: var(--full-padding);
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: auto;
	.table-header {
		font-weight: bold;
		color: var(--brand-accent-color);
		span {
			color: var(--brand-accent-color);
		}
	}
	.limit-dropdown {
		float: right;
		font-size: 15px;
		span {
			color: var(--brand-accent-color);
		}
		select {
			color: var(--brand-accent-color);
			border-radius: 5px;
			border-width: 2px;
			border-color: var(--brand-accent-color);
			padding-left: 8px;
			background-color: white;
			height: 36px;
		}
	}
	.greeting_Header{
		@include xbold();
		font-size: 30px;
	}
	.config-header-recent{
		padding: 5px 0 12px 0;

		span:first-child {
			font-size: 24px;
			@include bold();
		}
	}
	.config-header-search{
		font-size: 18px;
		padding: 5px 0 12px 0;
	}
	.pagination-panel {
		flex: 0;
		margin: 1em 0 0 0;
		min-height: 1; // intentionally not a variable - min height for IE
		span {
			color: var(--brand-accent-color);
			font-weight: bold;
		}
	}
	.search{
		flex: 0;
		margin: 3em 0 1.5em 0;
		min-height: 5.1em; // intentionally not a variable - min height for IE
		input{
			height: 50px;
			display: inline-block;
			width: #{$sidebarWidth};
			background: #{$backgroundColor}; // IE fallback
			background: var(--background-color);
			color: #{$textColor}; // IE fallback
			color: var(--text-color);
			border-radius: .5rem;
			border: 2px solid;
			border-color: $ink; 
			padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
			padding: var(--quarter-padding) var(--half-padding);
		}
		.find-header {
			@include bold();
			font-size: 24px;
		}
		button {
			height: 50px;
			border-radius: 6px;
		}
	}
	.submitButton{
		margin-left:5px;
	}
	.table a{
		display: inline-block;
		color: inherit;
		text-decoration: none;
		&.renew {
			display: inline-block;
			color: #{$errorColor}; // IE fallback;
			color: var(--error-color);
			background: none;
			border: none;
			text-decoration: underline;
			font-weight: bold;
		}
		&.expired{
			display: inline-block;
			color: #{$errorColor}; // IE fallback;
			color: var(--error-color);
		}
		&.expired:after{
			display: inline-block;
			content: '(expired)';
			color: #{$errorColor}; // IE fallback;
			color: var(--error-color);
		}
	}
}
.configurations{
	table{
		col:nth-child(1){
			width:5%;
			min-width:3em;
		}
		col:nth-child(2){
			 width:25%;
		}
		col:nth-child(3){
			 width:30%;
		}
		col:nth-child(4){
			width:15%;
		}
		col:nth-child(5){
			 width:10%;
		}
		col:nth-child(6){
			width:15%;
	   }
	}
}
#newConfiguration, #newCustomer{
	float: right;
}
.validation-messages {
	color: #{$errorColor};
	color: var(--error-color);
	margin: -1px 0 -2px 1px;
}
.searchDataEmpty{
	background-color: #f2f2f2;
	padding: 7px;
	margin-top: 1.2em;
}
.totalRecords{
	color:grey;
	font-style: italic;
}
.configLocked{
	color: #{$textColor};
	font-size: 20px;
}