$collectionLeft: 35%;
$collectionRight: 100% - $collectionLeft;
.collection-wrapper{
	& > .error-messages .error-message{
		padding: $fullPadding 0;
		padding: var(--full-padding) 0;
	}
}
.field-wrapper.collection {
	padding:0;
	width: 100%;
	position: relative;
	display: block;
	box-sizing: content-box;
}
.collection{
	position:relative;
	display: flex;
	width: 100%;
	max-width: 54rem;
	.collection-list,
	.collection-fields{
		position:relative;
		max-height: 500px;
		overflow-y: auto;
		.field-wrapper {
			&.ng-invalid:before {
				left: 1rem;
			}
		}
	}
	.introduction-text {
		padding: 0 var(--quarter-padding);
		font-weight: 700;
	}
	.collection-list{
		left:0;
		width: #{$collectionLeft};
		ul {
			margin: 0;
			padding: 0;
		}
		button{
			margin: $halfMargin; // IE fallback
			margin: var(--half-margin);
		}
		.fa-comment{
			color: #{$notificationColor}; // IE fallback
			color: var(--notification-color);
		}
		// IE hack:
		// need the flex css separate from the <li/>
		// https://github.com/philipwalton/flexbugs/issues/179
		// plus some weird extra pixels, i dunno?
		li{
			width: calc(100% - 1em);
			position: relative;
			&.active{
				background: #{$highlightColor}; // IE fallback
				background: var(--highlight-color);
			}
		}
		.collection-more{
			font-size: #{$normalFontSize};
			font-size: var(--normal-font-size);
			position: relative;
		}
		.collection-import {
			color: $gray;
			cursor: pointer;
			right: 0;
			top: 0;
			font-size: $iconFontSize;
			font-weight: bold;
			padding: 0 var(--full-padding);
			position: absolute;
 			&:hover {
				color: $black;
			}
		}

		.collection-item{
			cursor: pointer;
			list-style: none;
			padding: 0 #{$quarterPadding}; // IE fallback
			padding: 0 var(--quarter-padding);
			padding-left: $fullPadding; // IE fallback;
			padding-left: var(--full-padding);
			background: inherit;
			display: flex !important;
			flex-direction: row;
			width: 100%;
			align-items: center;
			position: relative;
			&.ng-invalid:before{
				margin-top: #{div(-$iconSize, 2)};
				left: $quarterPadding; // IE fallback
				left: var(--quarter-padding);
			};
			.collection-name, .collection-buttons, .collection-item-more{
				padding: #{$quarterPadding};
				padding: var(--quarter-padding);
			}
			.collection-name{
				flex: 1 1 auto; // `flex: 1;` breaks IE
				position: relative;
				overflow: hidden;
				font-size: #{$normalFontSize};
				font-size: var(--normal-font-size);
				line-height: #{$shortLineHeight};
				.field-text{
					white-space: normal;
					background: #{$backgroundColor}; // IE fallback
					background: var(--background-color);
				}
			}
			.collection-buttons{
				width: #{3 * $iconSize};
				.fa{
					font-size: #{$iconFontSize};
					padding: 0 0 0 #{$quarterPadding}; // IE fallback
					padding: 0 0 0 var(--quarter-padding);
				}
			}
			.collection-item-more{
				margin-left: #{$fullPadding};
				margin-left: var(--full-padding);
				padding: #{$quarterPadding};
				padding: var(--quarter-padding);
				align-self: flex-end;
			}
		}
	}
	.collection-fields{
		border: 2px solid #{$highlightColor}; // IE fallback
		border: 2px solid var(--highlight-color);
		width: #{$collectionRight};
		padding: #{$halfPadding} #{$fullPadding} #{$fullPadding} #{$doublePadding}; // IE fallback
		padding: var(--half-padding) var(--full-padding) var(--full-padding) var(--double-padding);
		h5 {
			margin-top: 0;
		}
	}
}
