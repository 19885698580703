.field-wrapper .alert {
	border: 2px solid;
	border-radius: 4px;
	padding: #{$fullPadding}; // IE fallback
	padding: var(--full-padding);
	margin: #{$fullMargin} 0; // IE fallback
	margin: var(--full-margin) 0;
	display: inline-block;
	position: relative;
	&:before{
		font: #{$fontAwesome};
		width: #{$iconSize};
		margin-right: #{$quarterMargin};
		margin-right: var(--quarter-margin);
		text-align:center;
		font-size: #{$titleFontSize}; // IE fallback;
		font-size: var(--title-font-size);
	}
	&.alert-warning {
		border-color: #{$validationColor}; // IE fallback
		border-color: var(--validation-color);
		background-color: #{$validationColor}; // IE fallback
		background-color: var(--validation-color);
		color: #{$textColor}; // IE fallback
		color: var(--text-color);
		&:before{
			content: #{$errorSymbol};
		}
	}
	&.alert-info {
		border-color: #{$brandColor}; // IE fallback
		border-color: var(--brand-color);
		color: #{$brandColor}; // IE fallback
		color: var(--brand-color);
		&:before{
			content: #{$fa-var-info-circle};
		}
	}
	.alert{
		border: none;
		padding: 0;
		margin: 0;
	}
}