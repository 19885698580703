$brandColor: $alexBlue;
$brandAccentColor: $accentBlue;
$backgroundColor: #fff;
$textColor: black;
$textAccentColor: $gray;
$accentColor: $lightGray;
$hoverColor: #eee;
$highlightColor: $lightBlue;
$actionColor: $yellow;
$successColor: $green;
$errorColor: $red;
$notificationColor: $purple;
$changesColor: $purple;
$darkGrey:#bfbcbc;
$disabledActionColor: #e1e3e3;
$disabledTextColor: #b5b7b7;
$lightGrayBackground: #f5f5f7;

@import "../../bower_components/font-awesome/scss/_variables";
$fontAwesome: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome;
@mixin normal(){
  font-family: 'Museo Sans 300', Verdana, Helvetica, Arial, sans-serif;
  font-weight: 300;
}
@mixin bold(){
  font-family: 'Museo Sans 700', Verdana, Helvetica, Arial, sans-serif;
  font-weight: 700;
}
@mixin xbold(){
  font-family: 'Museo Sans 900', Verdana, Helvetica, Arial, sans-serif;
  font-weight: 900;
}
@mixin title(){
  font-family: 'Foro Medium', serif;
  font-weight: 500;
}

$errorSymbol: $fa-var-exclamation-triangle;
$validationColor: $cyan;
$validationSymbol: $fa-var-arrow-right;
$validationFont: $fontAwesome;
$accordionClosedSymbol: $fa-var-caret-right;
$accordionOpenSymbol: $fa-var-caret-down;

// difference in height between main font and icon font
// used for positioning validation arrows so they line up with the normal font
$iconFontVsNormalFontRatio: .75;


$iconSize: 1em; // intentionally ems, not rems
$arrowSize: .5rem;
$loaderSize: 2.5rem;
$headerHeight: 2.5rem;
$extraMenuWidth: 12em;
$sidebarWidth: 17rem;
$configurationNavWidth: 22rem;
$formLabelWidth: 14rem;
$formMaxWidth: 25rem;
$questionLabelWidth: 8rem;
$inputHeight: 1.4rem;
$fieldPaddingLeft: 6em; // intentionally ems, makes space for "changes" button.
$modalZIndex: 9999;
$sidebarZIndex: 500;
$editButtonFieldWidth: 6.25rem;
$tierButtonWidth: 3.5rem;
$border-radius-small: 0.25rem;

$hugeLineHeight: 2.5;
$baseLineHeight: 1.75;
$shortLineHeight: 1.25;
$shorterLineHeight: 1.1;
$shortestLineHeight: 1;

$baseFontSize: 15px;
$tinyFontSize: .5rem;
$iconFontSize: .7rem;
$smallFontSize: 0.875rem;
$semiFontSize: .9rem;
$normalFontSize: 1rem;
$titleFontSize: 1.125rem;
$mediumFontSize: 1.25rem;
$bigFontSize: 1.5rem;
$hugeFontSize: 1.75rem;

$thinPadding: .1rem;
$quarterPadding: .25rem;
$halfPadding: .5rem;
$fullPadding: 1rem;
$fullPlusPadding: 1.5rem;
$doublePadding: 2rem;
$hugePadding:  4rem;

$thinMargin: .1rem;
$quarterMargin: .25rem;
$halfMargin: .5rem;
$fullMargin: 1rem;
$fullPlusMargin: 1.5rem;
$doubleMargin: 2rem;
$hugeMargin:  4rem;

$defaultShadow: 2px 4px 4px rgba(0,0,0,0.2);
$noShadow: 0 0 0 rgba(0,0,0,0);

$collectionLabelPositionWide: 5.5rem;
$collectionLabelPositionNarrow: 7rem;

// v-accordion $v-accordion-default-theme:         true !default;
$v-accordion-spacing:               20px !default;
$v-pane-border-color:               #D8D8D8 !default;
$v-pane-expanded-border-color:      #2196F3 !default;
$v-pane-icon-color:                 #2196F3 !default;
$v-pane-hover-color:                #2196F3 !default;
$v-pane-disabled-opacity:           0.6   !default;
$v-pane-expand-animation-duration:  0.5s  !default;
$v-pane-hover-animation-duration:   0.25s !default;
$fieldValueItems: '.field-text, .field-toggle, .field-dropdown, .field-date, .field-file-input-label, .field-picker .dropdown-multiselect';
$validationItems: '.field-wrapper, [applied-question], [question], .choices, .choice-value, .collection-item, .row input, .side-nav-item, .side-nav-section, .side-nav-title, .side-nav-sub-title';