.layout-drawer .side-nav{
	list-style: none;
	width: #{$sidebarWidth}; // IE Fallback
	width: var(--sidebar-width);
	padding: 0;
	h1,
	h2,
	h3 {
		margin: #{$halfMargin} 0 #{$fullMargin}; // IE fallback;
		margin: var(--half-margin) 0 var(--full-margin) ;
		font-weight: normal;
		width:100%;
		&:empty {
			display: none;
		}
	}
	h1 {
		font-size: #{$bigFontSize}; // IE fallback;
		font-size: var(--big-font-size);
		max-width: 6em;
	}
	h2 {
		font-size: #{$titleFontSize}; // IE fallback;
		font-size: var(--title-font-size);
		color: #{$textAccentColor}; // IE fallback
		color: var(--text-accent-color);
		max-width: 10em;
	}
	h3 {
		font-size: #{$normalFontSize}; // IE fallback;
		font-size: var(--normal-font-size);
		font-weight: bold;
		padding: 0;
		padding: 0;
	}
	a, .plan-type {
		text-decoration: none;
		cursor: pointer;
		display: block;
		color: #{$brandColor}; // IE-fallback;
		color: var(--brand-color);
		display: inline;
		border: none;
		background: none;
		outline: none;
		padding: 0;
	}
	a:hover,
	v-pane-header:hover {
		text-decoration: underline;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	li {
		position:relative;
		&.ng-invalid:before{
			top: 50%;
			margin-top: #{div(-$iconSize, 2)};
		}
	}
	& > li > * {
		padding: #{$halfPadding} #{$fullPadding}; // IE fallback;
		padding: var(--half-padding) var(--full-padding);
		display: inline-block;
	}
	[aria-hidden="true"] {
		display: none;
	}
	v-pane-header {
		word-break: break-word;
		&[aria-expanded="true"] {
			+ v-pane-content {
				margin-left: #{$halfMargin}; //IE Fallback
				margin-left: var(--half-margin);
				border-left: 2px solid #{$highlightColor}; //IE Fallback
				border-left: 2px solid var(--highlight-color);
				&.no-border {
					margin-left: 0;
					border: 0;
				}
				ul {
					margin-bottom: #{$fullMargin}; //IE Fallback
					margin-bottom: var(--full-margin);
					padding-left: #{$fullPadding}; // IE fallback;
					padding-left: var(--full-padding);
				}
			}
		}
	}
	v-accordion {
		> v-pane {
			margin-left: #{$fullMargin}; //ie
			margin-left: var(--full-margin);
		}
	}
	// prevents plan names from having blue links
	.expandable > div > a, .plan-type {
		color: #{$textColor}; //ie
		color: var(--text-color);
	}
	[aria-expanded="false"] {
		&.expandable {
			@include accordionClosed;
		}
		.expandable {
			@include accordionClosed;
		}
	}
	[aria-expanded="true"] {
		&.expandable {
			@include accordionOpen;
			+ v-pane-content {
				margin-bottom: #{$fullMargin}; //ie
				margin-bottom: var(--full-margin);
			}
		}
		.expandable {
			@include accordionOpen;
			+ v-pane-content {
				margin-bottom: #{$fullMargin}; //ie
				margin-bottom: var(--full-margin);
			}
		}
	}
	.index {
		color: #{$textAccentColor};
		color: var(--text-accent-color);
		font-weight: normal;
		display: inline-block;
		width: #{$iconSize};
		text-align: center;
	}
	&.primary-nav{
		padding-top: #{$fullPadding};
		padding-top: var(--full-padding);
		> li {
			border-bottom: 1px solid #{$backgroundColor}; // IE fallback
			border-bottom: 1px solid var(--background-color);
			padding: #{$halfPadding} 0; // IE fallback
			padding: var(--half-padding) 0;
			> div {
				width: 100%;
			}
		}

		.export-html-wrapper {
			padding: 0;
		}

		// make content invisible
		.export-html-content {
			bottom: -999999999999px;
			left: -99999999999px;
			height: 0;
			width: 0;
			overflow: hidden;
			position: absolute;
		}

		button.export-html-btn {
			margin-bottom: 0;
			i {
				margin-left: 4px;
			}
		}
	}
	.edit-configuration {
		display: flex;
		align-items: center;
		margin-bottom: #{$doubleMargin}; // IE fallback;
		margin-bottom: var(--double-margin);
		.name-input {
			padding: #{$halfPadding} #{$quarterPadding}; //IE fallback
			padding: var(--half-padding) var(--quarter-padding);
			border: 2px solid;
			border-color: #{$actionColor}; //ie fallback
			border-color: var(--action-color);
			border-radius: 0.5rem;
		}
		.configuration-button {
			margin-left: #{$quarterMargin}; // IE fallback;
			margin-left: var(--quarter-margin);
			margin-bottom: 0;
		}
		.fa-pencil {
			cursor: pointer;
			color: #{$brandColor}; //IE fallback
			color: var(--brand-color);
			margin-left: #{$halfMargin}; // IE fallback
			margin-left: var(--half-margin);
		}
	}
	.fa-comment {
		color: #{$notificationColor};
		position: absolute;
		top: 9px;
		right: 15px;
	}
	.side-nav-group {
		ul {
			height:auto;
		}
	}
	.side-nav-title {
		background: #{$highlightColor}; // IE fallback
		background: var(--highlight-color);
		border-bottom: 1px solid #{$backgroundColor};
		border-bottom: 1px solid var(--background-color);
		cursor: pointer;
		padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
		padding: var(--quarter-padding) var(--half-padding);
		@include xbold();
		text-transform: uppercase;
	}
	.side-nav-sub-group {
		position: relative;
	}
	.side-nav-sub-title {
		@include bold();
	}
	.side-nav-sub-title {
		padding: #{$quarterPadding} #{$doublePadding}; // IE fallback
		padding: var(--quarter-padding) var(--double-padding);
		text-transform: uppercase;
	}
	.side-nav-sub-nav {
		&[ng-repeat*="settings"] {
			padding-left: 0;
		}
		.side-nav-item {
			padding: #{$quarterPadding} 4rem #{$halfPadding} #{$doublePadding}; // IE fallback
			padding: var(--quarter-padding) 4rem var(--half-padding) var(--double-padding);
			&[aria-expanded="true"] {
				+ .side-nav-sub-sub-item {
					overflow: visible;
				}
			}
		}
	}
	.side-nav-sub-sub-title {
		padding: #{$quarterPadding} #{$halfPadding} #{$halfPadding} #{$doublePadding}; // IE fallback
		padding: var(--quarter-padding) var(--half-padding) var(--half-padding) var(--double-padding);
	}
	.side-nav-sub-sub-item {
		li {
			padding: 0 4rem #{$halfPadding} #{$halfPadding}; // IE fallback
			padding: 0 4rem var(--half-padding) var(--half-padding);
			word-break: break-word;
			a {
				line-height: 1;
			}
			&.ng-invalid:before {
				left: -0.5rem;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
	.side-nav-section {

		&.active {
			@include xbold();
			text-decoration: underline;
		}
		.fa-comment {
			top:  5px;
		}
	}
	.new-unit {
		color: #{$brandColor}; // IE-fallback;
		color: var(--brand-color);
		cursor: pointer;
		@include bold();
		padding-bottom: #{$halfPadding}; // IE fallback
		padding-bottom: var(--half-padding);
	}
	.activeUnit,
	.activeSection {
		@include xbold();
		border-bottom: 1px solid #{$backgroundColor};
		border-bottom: 1px solid var(--background-color);
	}
	.activePage,
	.activeUnit {
		@include xbold();
		text-decoration: underline;
	}
	.activeSection {
		a {
			@include xbold();
			text-decoration: underline;
		}
	}
	button {
		margin-bottom: $halfMargin; // IE fallback
		margin-bottom: var(--half-margin);
		&.plan-type {
			margin-bottom: 0;
			text-align: left;
		}
	}
	button.lock-button {
		/* todo: this will come back after locking is required for publishing */
		/*background: #{$validationColor};
		background: var(--validation-color);
		border-color: #{$validationColor};
		border-color: var(--validation-color);
		&:active,
		&:focus{
			border-color: #{$validationColor};
			border-color: var(--validation-color);
		}*/
	}
	button.preview-button {
		width: 100%;
	}
	.side-nav-title,
	.side-nav-sub-title,
	.side-nav-item,
	.side-nav-section {
		padding-left: #{$doublePadding}; // IE Fallback
		padding-left: var(--double-padding);
		padding-right: #{$doublePadding}; // IE Fallback
		padding-right: var(--double-padding);
		&.ng-invalid:before {
			left: 1.6em;
			font-size: #{$iconFontSize}; //ie fallback
			font-size: var(--icon-font-size);
			top: 0.75rem;
		}
		&:after {
			top: 0.4rem;
		}

		&.new-unit {
			padding-left: #{$halfPadding}; //ie
			padding-left: var(--half-padding);
			padding-bottom: 0;
		}
	}
	.side-nav-options{
		.side-nav-options-form{
			padding: #{$halfPadding}; // IE fallback;
			padding: var(--half-padding);
		}
		.side-nav-options-field{
			margin: #{$quarterMargin} #{$halfMargin}; // IE fallback;
			margin: var(--quarter-margin) var(--half-margin);
		}
	}
	.edited {
		position: absolute;
		display: block;
		right: 43px;
		top: 2px;
		text-transform: lowercase;
		font-style: italic;
		font-weight: normal;
		color: #{$changesColor}; // IE-fallback;
		color: var(--changes-color);
		font-size: #{$iconFontSize}; // IE-fallback;
		font-size: var(--icon-font-size);
		@include normal();
	}
	.activeSection {
		.edited {
			color: #{$backgroundColor}; // IE-fallback;
			color: var(--background-color);

		}
	}
	.side-nav-title {
		.edited {
			top: 7px;
		}
	}
	.side-nav-sub-title {
		.edited {
			top: 4px;
		}
	}
	.side-nav-sub-nav {
		.side-nav-item {
			.edited {
				top: 7px;
			}
		}
	}
	.customer-tier {
		width: 100%;
		margin: $halfMargin 0 $fullMargin;
		margin: var(--half-margin) 0 var(--full-margin);
		label {
			display: block;
			@include bold();
			color: #{$textAccentColor};
			color: var(--text-accent-color);
			text-transform: uppercase;
			font-size: #{$iconFontSize}; // IE fallback;
			font-size: var(--icon-font-size);
			line-height: #{$iconFontSize}; // IE fallback;
			line-height: var(--icon-font-size);
			vertical-align: top;
		}
		select {
			width: 100%;
		}
	}
	.view-options-field {
		margin-top: #{$fullMargin};
		margin-top: var(--full-margin);
	}
}
