.page-title{
	position: sticky;
	top: 0;
	background: #{$backgroundColor}; // IE fallback;
	background: var(--background-color);
	border-bottom: 1px solid;
	border-bottom-color: #{$accentColor}; // IE fallback;
	border-bottom-color: var(--accent-color);
	z-index: 3;
  font-size: #{$normalFontSize}; // IE fallback;
	font-size: var(--normal-font-size);
  padding: #{$halfPadding}; // IE fallback
  padding: var(--half-padding);
	h1, h3 {
		font-size: #{$mediumFontSize}; // IE fallback;
		font-size: var(--medium-font-size);
		display: inline-block;
		padding: 0;
		margin: 0;
	}
}
