@font-face {
  font-family: 'Foro Light';
  src: url('/fonts/foro/foro-light.eot');
  src:
    url('/fonts/foro/foro-light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/foro/foro-light.woff2') format('woff2'),
    url('/fonts/foro/foro-light.woff') format('woff'),
    url('/fonts/foro/foro-light.ttf') format('truetype'),
    url('/fonts/foro/foro-light.svg#foro-light') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Foro Light Italic';
  src: url('/fonts/foro/foro-light-italic.eot');
  src:
    url('/fonts/foro/foro-light-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/foro/foro-light-italic.woff2') format('woff2'),
    url('/fonts/foro/foro-light-italic.woff') format('woff'),
    url('/fonts/foro/foro-light-italic.ttf') format('truetype'),
    url('/fonts/foro/foro-light-italic.svg#foro-light-italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Foro Medium';
  src: url('/fonts/foro/foro-medium.eot');
  src:
    url('/fonts/foro/foro-medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/foro/foro-medium.woff2') format('woff2'),
    url('/fonts/foro/foro-medium.woff') format('woff'),
    url('/fonts/foro/foro-medium.ttf') format('truetype'),
    url('/fonts/foro/foro-medium.svg#foro-medium') format('svg');
}
@font-face {
  font-family: 'Foro Medium Italic';
  src: url('/fonts/foro/foro-medium-italic.eot');
  src:
    url('/fonts/foro/foro-medium-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/foro/foro-medium-italic.woff2') format('woff2'),
    url('/fonts/foro/foro-medium-italic.woff') format('woff'),
    url('/fonts/foro/foro-medium-italic.ttf') format('truetype'),
    url('/fonts/foro/foro-medium-italic.svg#foro-medium-italic') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
  font-family: 'Foro Bold';
  src: url('/fonts/foro/foro-bold.eot');
  src:
    url('/fonts/foro/foro-bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/foro/foro-bold.woff2') format('woff2'),
    url('/fonts/foro/foro-bold.woff') format('woff'),
    url('/fonts/foro/foro-bold.ttf') format('truetype'),
    url('/fonts/foro/foro-bold.svg#foro-bold') format('svg');
    font-weight: bold;
    font-style: normal;
}