.field-text {
	padding: 2px 2px 2px 0;
	border-top: 2px solid transparent;
	border-bottom: 2px dotted;
	border-bottom-color: #{$brandColor};
	border-bottom-color: var(--brand-color);
	width: fit-content;
	// Because it's hard to click on/see things that are small.
	&:empty, &:focus{
		min-width: 3rem;
		//cross browser arrow alignment fix
	}
	&:empty {
		padding: 0;
	}
	&:focus {
		border: 2px solid;
		border-color: #{$actionColor}; // IE fallback
		border-color: var(--action-color);
		border-radius: 0.5rem;
		box-shadow: inset 1px 1px .5rem #{$accentColor}; // IE fallback;
		box-shadow: inset 1px 1px .5rem var(--accent-color);
		padding-left: #{$quarterPadding};
		padding-left: var(--quarter-padding);
		padding-right: #{$quarterPadding};
		padding-right: var(--quarter-padding);
	}
	&.ng-invalid.ng-valid-required {
		color: #{$errorColor}; // IE fallback;
		color: var(--error-color);
	}
	&[disabled] {
		padding: #{$quarterPadding} 0 0; // IE fallback
		padding: var(--quarter-padding) 0 0;
		&:focus {
			border-left: 0px solid transparent;
			border-right: 0px solid transparent;
			border-bottom: 2px dotted;
			border-bottom-color: #{$accentColor}; // IE fallback
			border-bottom-color: var(--accent-color);
			border-top-color: transparent;
			border-radius: 0;
			box-shadow: none;
			min-width: 0;
		}
	}
	&.hidden{
		display:none;
	}
}
.field-text, input{
	&[dollars]{
		font-family: Helvetica, Arial, sans-serif;
	}
}
[contentEditable=true]:empty:not(:focus):after,
[contentEditable]:empty:not(:focus):after{
	content: attr(placeholder);
	border-bottom-color: #{$actionColor}; // IE-fallback;
	border-bottom-color: var(--action-color);
	font-style: italic;
}