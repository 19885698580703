.float-right {
	float: right;
}

.clearfix:before,
.clearfix:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.clearfix:after {
	clear: both;
}

*, *.field-text, *.field-text:focus{
	&.min-width-1 {
		min-width: 1em;
	}

	&.min-width-2 {
		min-width: 2em;
	}

	&.min-width-3 {
		min-width: 3em;
	}

	&.min-width-4 {
		min-width: 4em;
	}

	&.min-width-5 {
		min-width: 6em;
	}

	&.min-width-6 {
		min-width: 6em;
	}

	&.min-width-10 {
		min-width: 10em;
	}

	&.min-width-20 {
		min-width: 20em;
	}
}

@mixin fillTo($padding) {
	position: absolute;
	top: #{$padding};
	bottom: #{$padding};
	left: #{$padding};
	right: #{$padding};
	overflow:auto;
}

@mixin accordionOpen {
	&:after {
		content: #{'"' + $accordionOpenSymbol + '"'};
		font: #{$validationFont};
		color: #{$brandColor};
		color: var(--brand-color); // IE fallback
		position: absolute;
		left: 0.25rem;
		top: 0.5rem;
		font-size: 1.2rem;
	}
}

@mixin accordionClosed {
	&:after {
		content: #{'"' + $accordionClosedSymbol + '"'};
		font: #{$validationFont};
		color: #{$brandColor};
		color: var(--brand-color); // IE fallback
		position: absolute;
		left: 0.25rem;
		top: 0.5rem;
		font-size: 1.2rem;
	}
}
