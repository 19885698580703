.historic-value{
  display: flex;
  width: 100%;
  position: relative;
  &.added{
    background: $successColor; // IE fallback
    background: var(--success-color); // IE fallback
  }
  &.removed{
    background: $errorColor; // IE fallback
    background: var(--error-color); // IE fallback
  }
  .field-wrapper{
    padding: $halfMargin;
    padding: var(--half-padding);
  }
}

// the following is all context-aware css positioning.
// based on the field type, where should the changes button go?
// eventually, we'll wanna make this positioned with flexbox, not absolute and `fieldPaddingLeft`;
// but that's a risky change, so not making it now
.field-wrapper > .history-button {
  position: absolute;
  left: -1.5em;
  top: 0;
}
.group .field-wrapper > .history-button {
  left: -7em;
}

.field-wrapper > .history-button:before {
  font: #{$fontAwesome};
  content: #{'"' + $fa-var-caret-right + '"'};
}
.eligibility > .history-button {
  position: absolute;
  right: 100%;
  top: 0;
  margin-right: $fullMargin;
  margin-right: var(--full-margin);
  margin-top: $fullMargin;
  margin-top: var(--full-margin);
}
.collection-item .history,
//hiding the button for history on collection items too, if we're hiding the history panel
.collection-item .history-button {
  display: none;
}

// for eligibility and collections, we dig deeper into history than top level changes
// so don't display the top level button
.field-wrapper.eligibility > .history-button{
	display: none;
}
.collection > .history-button{
  display: none;
}
