.extra-menu {
	position: absolute;
	z-index: 1000;
	width: #{$extraMenuWidth};
	padding: #{$fullPadding}; // IE fallback
	padding: var(--full-padding);
	margin-left: #{$quarterPadding}; // IE fallback
	margin-left: var(--quarter-padding);
	border-color: #{$highlightColor}; // IE fallback;
	border-color: var(--highlight-color);
	background: #{$highlightColor}; // IE fallback
	background: var(--highlight-color);
	color: #{$textColor}; // IE fallback
	color: var(--text-color);

	&:before{
		content: '';
		position: absolute;
		right: 100%;
		top: 10%;
		border-right-width: #{$arrowSize};
		border-right-style: solid;
		border-right-color: inherit;
		border-top-width: #{$arrowSize};
		border-top-style: solid;
		border-top-color: transparent;
		border-bottom-width: #{$arrowSize};
		border-bottom-style: solid;
		border-bottom-color: transparent;
		z-index: 100;
		background: transparent;
	}
	.extra-menu-item{
		cursor: pointer;
		display: block;
	}
}
.extra-menu-mask{
	@include fillTo(0);
	position: fixed;
	z-index: 999;
	padding: #{$fullPadding}; // IE fallback;
	padding: var(--full-padding);
}
.more {
	opacity: 1;
	display: inline-block;
	padding: 0 #{$halfPadding};
	padding: 0 var(--half-padding);
	color: #{$brandColor}; // IE fallback
	color: var(--brand-color);
}
