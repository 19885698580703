.plain-form{
	position:relative;
	padding: #{$fullPadding}; // IE fallback
	padding: var(--full-padding);
	h3{
		display:inline-block
	}
	.group{
		position:relative;
		padding: #{$fullPadding} 0; // IE fallback
		padding: var(--full-padding) 0;
		.title{
			font-size: #{$titleFontSize}; // IE fallback;
			font-size: var(--title-font-size);
			vertical-align: middle;
		}
		button{
			display: block;
			position: relative;
			left: #{$formLabelWidth};
			margin: #{$fullMargin} 0; // IE fallback;
			margin: var(--full-margin) 0;
		}
		.note{
			background: #{$validationColor}; // IE fallback;
			background: var(--validation-color);
			margin: #{$halfMargin} 0; // IE fallback;
			margin: var(--half-margin) 0;
			padding: #{$halfPadding} #{$fullPadding}; // IE fallback
			padding: var(--half-padding) var(--full-padding);
			border-radius: 1000rem;
			display:inline-block;
			color: #{$textColor}; // IE fallback
			color: var(--text-color);
			max-width: fit-content;
		}
		.row{
			padding: #{$halfPadding} 0; // IE fallback
			padding: var(--half-padding) 0;
			padding-left: #{$formLabelWidth};
			position: relative;
			label{
				width: #{$formLabelWidth};
				display:inline-block;
				text-align:left;
				position:absolute;
				left: 0;
				top: 0;
				padding: #{$halfPadding} 0 #{$quarterPadding} #{$doublePadding}; // IE fallback
				padding: var(--half-padding) var(--quarter-padding) var(--quarter-padding) var(--double-padding);
			}
			input{
				position: relative;
				vertical-align: baseline;
				&.ng-invalid:before {
				  top: 0;
				}
			}
			.checkbox-row label {
				position: static;
			}
			span[field-text]{
				min-width: 3rem;
				position:relative;
			}
			.clarification{
				color: #{$textAccentColor}; // IE fallback
				color: var(--text-accent-color);
				font-style: italic;
				font-size: #{$semiFontSize};
				display: block;
				max-width: #{$formMaxWidth};
			}
		}
	}
}