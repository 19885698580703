.eligibility-question-page {
	h2 {
		margin: 0 0 #{$fullMargin}; //ie fallback
		margin: 0 0 var(--full-margin);
	}
	.empty-message {
		border-style: solid;
		border-width: .1rem;
		border-color: #{$highlightColor}; // IE fallback
		border-color: var(--highlight-color);
		border-radius: .5rem;
		color: #{$gray};
		font-family: 'Museo Sans 500', Verdana, Helvetica, Arial, sans-serif;
		font-weight: 500;
		font-style: italic;
		margin: 0 0 #{$fullMargin}; //ie fallback
		margin: 0 0 var(--full-margin);
		padding: #{$halfPadding}; // IE fallback;
		padding: var(--half-padding);
	}
	.question-section-container {
		margin: 0 0 #{$doubleMargin}; //ie fallback
		margin: 0 0 var(--double-margin);
	}
}
.eligibility-questions {
	overflow: hidden;
	.add-eligibility-question-controls {
		margin-top: #{$fullMargin}; // IE fallback
		margin-top: var(--full-margin);
	}
	p{
		color: #{$textAccentColor}; // IE fallback
		color: var(--text-accent-color);
	}
}
.eligibility{
	position: relative;
	.alert{
		right: #{$fullPadding}; // IE fallback
  		right: var(--full-padding);
	}
}
.question{
	position:relative;
	background: #{$accentColor}; // IE Fallback
	background: var(--accent-color);
	margin-bottom: #{$halfMargin};
	margin-bottom: var(--half-margin);
	padding: #{$fullPlusPadding} #{$fullPadding} #{$fullPadding}; // IE fallback
	padding: var(--full-plus-padding) var(--full-padding) var(--full-padding);
	&:empty {
		display: none;
	}
	.ng-invalid:before{
		margin-left: -$fullPadding;
	}
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.question-buttons{
		position: absolute;
		top: 0;
		right: 0;
		button {
			padding: #{$quarterPadding}; //IE fallback
			padding: var(--quarter-padding);
		}
	}
	.question-row{
		margin-top: #{$halfMargin}; // IE fallback;
		margin-top: var(--half-margin);
		& > label {
			display: inline-block;
			@include bold();
			color: #{$textAccentColor}; // IE fallback
			color: var(--text-accent-color);
			text-transform: uppercase;
			font-size: #{$semiFontSize}; // IE fallback;
			font-size: var(--semi-font-size);
			width: #{$questionLabelWidth};
			vertical-align: top;
		}
	}
	.answers{
		display:inline-block;
		width: 18rem;
		li {
			position:relative;
			display:inline-block;
			width: 100%;
		}
		.add-choice{
			margin-top: #{$halfMargin}; // IE fallback;
			margin-top: var(--half-margin);
		}
	}
	.question-title{
		@include bold();
	}
	.clarification, .question-title, .choice-value, .eligibility-choice-label, .error-text, .in-use{
		display: inline-block;
		min-height: #{$inputHeight};
		padding: 0 #{$quarterPadding}; // IE fallback;
		padding: 0 var(--quarter-padding);
	}
	.error-text, .in-use{
		font-size: #{$semiFontSize};
		font-size: var(--semi-font-size);
	}
	.in-use{
		color: #{$brandAccentColor}; // IE fallback
		color: var(--brand-accent-color);
	}
	.zip{
		width: auto;
		.download-link{
			display: none;
		}
	}
	// extra specification to override tier max-width
	button.history-button {
		max-width: none;
	}
}
