#{$validationItems} {
	&.ng-invalid:before{
		content: #{'"' + $validationSymbol + '"'};
  		font: #{$validationFont};
		color: #{$validationColor};
  		color: var(--validation-color); // IE fallback
		position: absolute;
		left: #{-$iconSize * $iconFontVsNormalFontRatio};
		top: #{$iconSize * $iconFontVsNormalFontRatio};
		pointer-events: none;
	}
}

.error-messages {
	color: #{$errorColor}; // IE fallback;
	color: var(--error-color);
	display: none;
}

.error-messages-displayed {
	color: #{$errorColor}; // IE fallback;
	color: var(--error-color);
}


.charCounter{
	color: #{$textAccentColor};
	color: var(--text-accent-color);
	display: inline-block;
	font-size: #{$semiFontSize}; // IE fallback;
	font-size: var(--semi-font-size);
	font-style: italic;
	margin: 0 0 0 #{$quarterMargin}; // IE fallback;
	margin: 0 0 0 var(--quarter-margin);
}
.visited .error-messages, .visited ~ .error-messages, .collection-wrapper > .error-messages{
	display: block;
}
.error-message-required {
	display: none;
}
.field-wrapper.ng-dirty:not(.group):not(.collection) .error-message-required {
	display: block;
}

.field-wrapper:not(.group):not(.collection) .error-message {
	display: block;
}