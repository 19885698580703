$highlights: '.header, .side-nav-group .side-nav-title, .field-toggle, .side-nav li.active, .page-title';

@mixin static-background(){
	background: var(--background-design-main);
	background: var(--background-design);
	background-blend-mode: var(--background-blend-mode);
	background-attachment: fixed;
	background-position: var(--background-position);
	background-size: var(--background-size);
}

// Default Theme
body{
	--background-color: #{$backgroundColor};
	--text-color: #{$textColor};
	--text-accent-color: #{$textAccentColor};
	--accent-color: #{$accentColor};
	--hover-color: #{$hoverColor};
	--highlight-color: #{$highlightColor};
	--success-color: #{$successColor};
	--error-color: #{$errorColor};
	--validation-color: #{$validationColor};
	--brand-color: #{$brandColor};
	--brand-accent-color: #{$brandAccentColor};
	--action-color: #{$actionColor};
	--notification-color: #{$notificationColor};
	--changes-color: #{$changesColor};

	font-size: #{$baseFontSize};
	--tiny-font-size: #{$tinyFontSize};
	--icon-font-size: #{$iconFontSize};
	--semi-font-size: #{$semiFontSize};
	--icon-font-size: #{$iconFontSize};
	--normal-font-size: #{$normalFontSize};
	--title-font-size: #{$titleFontSize};
	--medium-font-size: #{$mediumFontSize};
	--big-font-size: #{$bigFontSize};
	--huge-font-size: #{$hugeFontSize};

	--thin-padding: #{$thinPadding};
	--quarter-padding: #{$quarterPadding};
	--half-padding: #{$halfPadding};
	--full-padding: #{$fullPadding};
	--full-plus-padding: #{$fullPlusPadding};
	--double-padding: #{$doublePadding};
	--huge-padding: #{$hugePadding};

	--quarter-margin: #{$quarterMargin};
	--half-margin: #{$halfMargin};
	--full-margin: #{$fullMargin};
	--full-plus-margin: #{$fullPlusMargin};
	--double-margin: #{$doubleMargin};

	--sidebar-width: #{$sidebarWidth};
	--configuration-nav-width: #{$configurationNavWidth};
}

// If other themes existed, they'd go here

body{
	background: #{$backgroundColor}; // IE fallback;
	background: var(--background-color);
	color: #{$textColor}; // IE fallback;
	color: var(--text-color);
	::-webkit-scrollbar {
		width: .5rem; // intentionally not variable
	}
	::-webkit-scrollbar-thumb {
		background: var(--accent-color);
	}
	::-webkit-scrollbar-track { 
		background: var(--background-color);	
	}
	h1, h2, h3 {
	  margin: #{$fullMargin} 0 #{$halfMargin}; // IE fallback
	  margin: var(--full-margin) 0 var(--half-margin);
	}
	h4, h5, h6 {
	  margin: #{$doubleMargin} 0 #{$quarterMargin}; // IE fallback
	  margin: var(--double-margin) 0 var(--quarter-margin); // IE fallback
	}
	.side-nav-section{
		display: block;
	}
}