.tier-type{
	select {
	padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
	padding: var(--quarter-padding) var(--half-padding);
	display: block;
	box-sizing: content-box;
	border: 1px solid #aaa;
	}
}
.tier {
	position: relative;
	font-size: #{$semiFontSize};
	margin-top: 2em; // intentionally not a variable
	&:after {
		content: 'annual amount';
		position: absolute;
		@include bold();
		bottom: 100%;
		right: 0;
		padding: #{$quarterPadding}; // IE fallback
		padding: var(--quarter-padding);
	}
	&.collection {
		margin-top: 0;
		&:after {
			content: "";
		}
		&:before {
			top: 1rem;
			left: 2rem;
		}
		.collection-fields {
			&:after {
				content: 'annual amount';
				position: absolute;
				@include bold();
				right: 3.25rem;
				top: #{$collectionLabelPositionWide};
				z-index: -1;
				// weirdly specific media query for when the contributions line breaks to two lines
				@media (max-width: 1474px) {
					top: #{$collectionLabelPositionNarrow};
				}
				.expanded-side-nav & {
					@media (max-width: 1474px) {
						top: #{$collectionLabelPositionWide};
					}
					@media (max-width: 1142px) {
						top: #{$collectionLabelPositionNarrow};
					}
				}
			}
		}
		.field-wrapper {
			margin-bottom: 0;
		}
	}
	&.group {
		max-width: 34rem;
		div[ng-form*="_amt"] {
			margin-bottom: #{$fullMargin}; // IE Fallback
			margin-bottom: var(--full-margin);
		}
		.field-wrapper{
			.history-button{
				left: -7.5em;
			}
		}
	}
	.history-button{
		max-width: #{$tierButtonWidth};
		padding: #{$quarterPadding}; //IE fallback
		padding: var(--quarter-padding);
	}
	.history {
		width: calc(100% + #{$tierButtonWidth} + #{$fullPadding});
  		margin-left: calc(-#{$tierButtonWidth} - #{$fullPadding});
  		position: relative;
  		top: 1.1rem;

  		.field-wrapper {
  			padding: #{$halfPadding};
			padding: var(--half-padding);
			display: block;
  		}
	}
	.field-label{
		width: 100%;
		comment-bubble {
			margin: 0 0 0 0;
			order: 1;
		}
		comment-box {
			flex: 1 0 100%;
			order: 2;
			.is-expanded {
				width: 100%;
			}
		}
	}
	div[ng-form*="_amt"], div[ng-form*="_code"]{
		padding: #{$halfPadding} #{$halfPadding} #{$halfPadding} #{$fullPadding}; // IE fallback
		padding: var(--half-padding) var(--half-padding) var(--half-padding) var(--full-padding);
		padding-left: calc(#{$fieldPaddingLeft} - #{$tierButtonWidth} + #{$fullMargin}); // intentionally not a variable
		clear:both;
		.field-label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			> span:not(.ng-pristine):first-child {
				max-width: 8rem;
			}
		}
		.field-text{
			position: relative;
			float: right;
			width: 7rem;
			text-align: right;
			background: #{$backgroundColor}; // IE fallback
			background: var(--background-color);
			color: #{$textColor};
			color: var(--text-color);
			border-radius: 0;
			border: 1px solid;
			border-color: #{$accentColor}; // IE fallback;
			border-color: var(--accent-color);
			margin: 0 0 0 auto;
			padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
			padding: var(--quarter-padding) var(--half-padding);
			&:before {
				content: '$';
				position: absolute;
				bottom: 0;
				right: 100%;
				padding: inherit;
			}
		}
	}
	div[ng-form*="_option_code"]{
		.field-text{
			&:before {
				content: 'option code';
			}
		}
	}
	.field-wrapper {
		padding: #{$halfPadding} #{$halfPadding} #{$fullPadding} #{$fullPadding}; // IE fallback
		padding: var(--half-padding) var(--half-padding) var(--full-padding) var(--full-padding);
		padding-left: calc(#{$fieldPaddingLeft} - #{$tierButtonWidth} + #{$fullMargin}); // intentionally not a variable
	}
	.group {
		&:not([inline]) .field-wrapper{
			padding: #{$halfPadding} #{$halfPadding} #{$fullPadding} 0; // IE fallback
			padding: var(--half-padding) var(--half-padding) var(--full-padding) 0;
			&.ng-invalid::before {
				left: -1em;
			}
		}
	}
}
.pay-period-label{
	text-align: right;
	margin-right: 28px;
}
