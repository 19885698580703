.ball {
  font-size:3rem;
  width: 3em;
  height: 3em;
  display: block;
  background-color: $brandColor;
  background-color: var(--brand-color);
  border-color: $brandColor;
  border-color: var(--brand-color);
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: inset -.5em .5em 0 -.33em rgba(0, 0, 0, .25);
  animation-fill-mode: both;
  -webkit-animation: wobble 1.3s infinite ease-in-out alternate;
  animation: wobble 1.3s infinite ease-in-out alternate;
  transform-origin: center bottom;
  .face{
    top:.5em;
  }
}
@-webkit-keyframes wobble {
  0%{
    transform: rotateZ(-10deg);
  }
  100%{
    transform: rotateZ(10deg);
  }
}
@keyframes wobble {
  0%{
    transform: rotateZ(-10deg);
  }
  100%{
    transform: rotateZ(10deg);
  }
}