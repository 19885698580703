.encyclopedia-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  td {
    width: 50%;
  }

  .field-display-text{
    padding: #{$halfPadding}; // IE fallback
    padding: var(--half-padding);
    background: $brandColor;
    background: var(--brand-color);
    color: white;
    font-weight: bold;
    margin: #{$halfMargin} 3rem 0 #{$halfMargin};
    margin: var(--half-margin) 3rem 0 var(--half-margin);
    display: flex;
    flex: 0 0 auto;
  }
  .fa-question-circle-o{
    align-self: center;
    padding-right: #{$fullPadding}; // IE fallback
    padding-right: var(--full-padding);
    font-size: 24px;
  }

  .s3-content {
    padding: #{$halfPadding}; // IE fallback
    padding: var(--half-padding);
    overflow-y: auto;

    // CSS pasted from the confluence space for Encyclopedia content formatting
    h1 {
      font-family: "Museo Sans", Helvetica, Arial, sans-serif;
      font-size: 2em;
      font-weight: 700;
      color: #{$textColor}; // IE fallback
      color: var(--text-color);
      margin-bottom: #{$fullMargin};
      margin-bottom: var(--full-margin);

      a {
        color: #{$textColor}; // IE fallback
        color: var(--text-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline
        }
      }
    }

    h2 {
      font-family: "Museo Sans", Helvetica, Arial, sans-serif;
      font-size: 1.75em;
      font-weight: 500;
      color: #{$textColor}; // IE fallback
      color: var(--text-color);
      margin-bottom: #{$fullMargin};
      margin-bottom: var(--full-margin);
    }

    h3 {
      font-family: "Museo Sans", Helvetica, Arial, sans-serif;
      font-size: 1.25em;
      font-weight: 900;
      text-transform: uppercase;
      color: #{$textAccentColor}; // IE fallback
      color: var(--text-accent-color);
      letter-spacing: 0.5px;
      margin-bottom: 0;
    }

    p,
    li {
      font-family: "Museo Sans", Helvetica, Arial, sans-serif;
      font-weight: 300;
      font-size: 1.1em;
      margin-bottom: #{$halfMargin};
      margin-bottom: var(--half-margin);
    }

    a {
      font-family: "Museo Sans", Helvetica, Arial, sans-serif;
      color: #{$brandColor}; // IE fallback
      color: var(--brand-color);
      text-decoration: underline;
    }

    img {
      cursor: pointer;
    }

    table,th,td {
      border: 1px solid;
      padding: 6px;
    }
  }
}
