@import "fonts/museo-sans";
@import "fonts/foro";
$fa-font-path: '/fonts/font-awesome';
@import "../../bower_components/font-awesome/scss/font-awesome";

html, body {
  @include normal();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #{$textColor}; // IE fallback
  color: var(--text-color);
}

body {
  font-size: #{$baseFontSize};
  line-height: #{$baseLineHeight};
}

a, div, a:hover, a:active, a:focus {
  outline:none;
}

h1, h2, h3 {
  margin: #{$fullMargin} 0 #{$fullMargin}; // IE fallback
  margin: var(--full-margin) 0 var(--full-margin);
}

h4, h5, h6 {
  margin: #{$doubleMargin} 0 #{$quarterMargin}; // IE fallback
  margin: var(--double-margin) 0 var(--quarter-margin);
}

h1 {
  font-size: #{$hugeFontSize}; // IE fallback;
  font-size: var(--huge-font-size);
  line-height: #{$shortLineHeight};
  @include title();
}
h2 {
  font-size: #{$bigFontSize};
  font-size: var(--big-font-size);
  line-height: #{$shorterLineHeight};
  @include title();
}
h3 {
  font-size: #{$mediumFontSize};
  font-size: var(--medium-font-size);
  line-height: #{$shorterLineHeight};
  @include bold();
}
h4 {
  font-size: #{$titleFontSize};
  font-size: var(--title-font-size);
  line-height: #{$shorterLineHeight};
  @include bold();
}
h5 {
  font-size: #{$titleFontSize};
  font-size: var(--title-font-size);
  line-height: #{$shorterLineHeight};
  @include bold();
}

h6 {
  font-size: #{$normalFontSize};
  font-size: var(--normal-font-size);
  line-height: #{$shorterLineHeight};
  text-transform: uppercase;
  color: #{$textAccentColor}; // IE fallback;
  color: var(--text-accent-color);
  @include bold();
}

blockquote {
  border-left: .25rem solid;
  border-left-color: #{$accentColor}; // IE fallback
  border-left-color: var(--accent-color);
  padding: 0 0 0 #{$fullPadding}; // IE fallback
  padding: 0 0 0 var(--full-padding);
  margin: 0 0 #{$fullPadding}; // IE fallback
  margin: 0 0 var(--full-padding);
}

pre{
  display: inline-block;
  color: #{$brandColor}; // IE-fallback;
  color: var(--brand-color);
}

blockquote p {
  margin-bottom: #{$halfMargin}; // IE fallback
  margin-bottom: var(--half-margin);
}

hr {
  margin: #{$fullPadding} 0; // IE fallback
  margin: var(--full-padding) 0;
}

p {
  margin-top: 0;
}
