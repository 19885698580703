.comment-box {
  .is-expanded {
    background: #{$backgroundColor};
    background: var(--background-color);
    border: 2px solid #{$notificationColor}; // IE fallback;
    border: 2px solid var(--notification-color);
    margin: #{$halfMargin} 0; // IE fallback;
    margin: var(--half-margin) 0;
    overflow: scroll;
    height: 20rem;
    width: inherit;
  }
  .odd-comment {
    background: #{$highlightColor}; // IE fallback;
    background: var(--highlight-color);
  }
  .expandable {
    overflow: hidden;
  }
  .heading-collapsed, .heading-expanded {
    cursor: pointer;
    padding: #{$halfPadding}; // IE fallback;
    padding: var(--half-padding);
    span {
      @include bold();
      align-self: flex-end;
      color: #{$brandColor}; // IE fallback;
      color: var(--brand-color);
    }
  }
  .resolved-comment {
    background: #{$lightGray}; // IE fallback;
  }
  .comment {
    padding: #{$halfPadding}; // IE fallback;
    padding: var(--half-padding);
  }
  .resolve-status {
    cursor: pointer;
    @include bold();
    color: #{$brandColor}; // IE fallback;
    color: var(--brand-color);
    font-size: #{$iconFontSize}; // IE fallback
    font-size: var(--icon-font-size);
    padding: 0 #{$quarterPadding}; // IE fallback
    padding: 0 var(--quarter-padding);
  }
  .fa {
    cursor: pointer;
    color: #{$brandColor}; // IE fallback;
    color: var(--brand-color);
    font-size: #{$normalFontSize}; // IE fallback;
    font-size: var(--normal-font-size);
    span {
      @include bold();
      font-size: #{$iconFontSize}; // IE fallback
      font-size: var(--icon-font-size);
      padding: 0 #{$quarterPadding}; // IE fallback
      padding: 0 var(--quarter-padding);
    }
  }
  .close-comments {
    background: #{$lightGray};
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: #{$halfPadding}; // IE fallback;
    padding: var(--half-padding);
    span {
      @include bold();
      align-self: flex-end;
      color: #{$brandColor}; // IE fallback;
      color: var(--brand-color);
    }
  }
  .comment-data {
    padding: 0 0 0 #{$halfPadding}; // IE fallback;
    padding: 0 0 0 var(--half-padding);
    font-size: #{$semiFontSize}; // IE fallback;
    font-size: var(--semi-font-size);
    .comment-author {
      font-weight: bold;
    }
    .comment-date {
      font-style: italic;
      font-size: #{$iconFontSize}; // IE fallback;
      font-size: var(--icon-font-size);
      margin-right: #{$quarterMargin}; // IE fallback
      margin-right: var(--quarter-margin);
    }
    // span {
    //   margin-right: #{$fullMargin}; // IE fallback;
    //   margin-right: var(--full-margin);
    // }
    .fa-trash {
      float: right;
      margin-right: #{$thinMargin}; // IE fallback;
      margin-right: var(--thin-margin);
      &:hover {
        color: $errorColor; // IE fallback;
        color: var(--error-color);
      }
    }
  }
  .edit-comment {
    margin: #{$fullPlusMargin} #{$halfMargin} 0 #{$halfMargin}; // IE fallback;
    margin: var(--half-margin) var(--half-margin) 0 var(--half-margin);
    textarea {
      width: 98%;
      margin-top: #{$halfMargin}; // IE fallback;
      margin-top: var(--half-margin);
    }
  }
  .comments-button {
    margin: #{$halfMargin}; // IE fallback;
    margin: var(--half-margin);
  }
}

comment-bubble {
  .fa {
    font-size: #{$normalFontSize}; // IE fallback;
    font-size: var(--normal-font-size);
  }
  .fa.fa-comment-o {
    margin-left: #{$thinMargin}; // IE fallback;
    margin-left: var(--thin-margin);
    margin-right: #{$quarterMargin}; // IE fallback;
    margin-right: var(--quarter-margin);
    padding: #{$quarterPadding}; // IE fallback
    padding: var(--quarter-padding);
    cursor: pointer;
    color: #{$notificationColor}; // IE fallback;
    color: var(--notification-color);
    transition: font-size 0.35s ease;
    &:hover {
      background: #{$highlightColor}; // IE fallback
      background: var(--highlight-color);
    }
    &.active-icon {
      background: #{$notificationColor}; // IE fallback;
      background: var(--notification-color);
      color: #{$backgroundColor}; //IE fallback
      color: var(--background-color);
      &:hover {
        color: #{$highlightColor}; // IE fallback
        color: var(--highlight-color);
      }
    }
  }
  .fa.fa-comment {
    margin-left: #{$thinMargin}; // IE fallback;
    margin-left: var(--thin-margin);
    margin-right: #{$quarterMargin}; // IE fallback;
    margin-right: var(--quarter-margin);
    cursor: pointer;
    color: #{$notificationColor}; // IE fallback;
    color: var(--notification-color);
    transition: font-size 0.35s ease;
    padding: #{$quarterPadding}; // IE fallback
    padding: var(--quarter-padding);
    &:hover {
      background: #{$highlightColor}; // IE fallback
      background: var(--highlight-color);
    }
    &.all-comments-resolved {
      color: #{$accentColor}; // IE fallback;
      color: var(--accent-color);
    }
    &.active-icon {
      background: #{$notificationColor}; // IE fallback;
      background: var(--notification-color);
      color: #{$backgroundColor}; //IE fallback
      color: var(--background-color);
      &:hover {
        color: #{$highlightColor}; // IE fallback
        color: var(--highlight-color);
      }
      &.all-comments-resolved {
        background: #{$accentColor}; // IE fallback;
        background: var(--accent-color);
        color: #{$backgroundColor}; //IE fallback
        color: var(--background-color);
        &:hover {
          color: #{$highlightColor}; // IE fallback
          color: var(--highlight-color);
        }
      }
    }
  }
}