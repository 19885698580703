.layout-button-main{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 6;
	border: 0;
	width: 2.5rem;
	height: 2.5rem;
	background: #{$backgroundColor}; // IE fallback
	background: var(--background-color);
	box-shadow: #{$defaultShadow};
	.expanded-side-nav & {
		box-shadow: #{$noShadow};
	}
}
.layout-drawer, .layout-main-panel{
	padding: 0;
	overflow: auto;
	margin: 0;
	transition: width 0.25s ease-in-out, padding 0.25s ease-in-out;
}
.layout-drawer{
	background: inherit;
	border-right: 1px solid;
	border-right-color: #{$accentColor}; // IE fallback;
	border-right-color: var(--accent-color);
	width: #{$sidebarWidth};
	flex: 0 0 auto;
	box-sizing: content-box;
	&.primary-nav {
		background: #{$accentColor}; // IE fallback
		background: var(--accent-color);
		box-shadow: inset #{$defaultShadow};
		position: relative;
		.activePage {
			background: #{$highlightColor}; // IE fallback
			background: var(--highlight-color);
			border-left: 0;
			border-right: 0;
		}
		.side-nav {
			max-width: 90%; // IE fallback
			max-width: calc(100% - 2rem);
		}
	}
	&.configuration-nav {
		width: #{$configurationNavWidth};
		.side-nav {
			width: 100%;
		}
	}
}
.layout-main-panel {
	flex: 1 1 auto;
}
.expanded-side-nav {
	.layout-button-main{
		.fa:before{
	  	content: #{$fa-var-chevron-right};
	  }
	}
	.layout-drawer{
		width: 0;
		padding-left: 2.5rem;
		margin-left: -1px;
		overflow: hidden;
		background: #{$backgroundColor}; // IE fallback
  		background: var(--background-color);
  		box-shadow: #{$noShadow};
	}
	.layout-drawer ~ .layout-drawer{
		width: 22rem;
		padding-left: 0;
		margin-left: 0;
		overflow: auto;
	}
}
