.loader{
	text-align:center;
	.bg{
		z-index:0;
		background: #{$black};
		opacity:.5;
		@include fillTo(0);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	}
	.wheel:before,
	.wheel:after,
	.wheel {
		height: #{$loaderSize};
		width: #{$loaderSize};
		border-radius: 50%;
		-webkit-animation-fill-mode: both;
		-moz-animation-fill-mode: both;
		-ms-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: wheel 1.8s infinite ease-in-out;
		-moz-animation: wheel 1.8s infinite ease-in-out;
		-ms-animation: wheel 1.8s infinite ease-in-out;
		animation: wheel 1.8s infinite ease-in-out;
		top:-$loaderSize;
	}
	.wheel{
		contain: size layout;
		background-size:auto 100%;
		background-repeat:no-repeat;
		background-position:center center;
		background-color:transparent;
		display:inline-block;
		margin:.5rem 0;
		position: relative;
		text-indent: -9999rem;
		-webkit-animation-delay: 0.16s;
		-moz-animation-delay: 0.16s;
		-ms-animation-delay: 0.16s;
		animation-delay: 0.16s;
		&:before {
			left: -3.5rem;
		}
		&:after {
			left: 3.5rem;
			-webkit-animation-delay: 0.32s;
			-moz-animation-delay: 0.32s;
			-ms-animation-delay: 0.32s;
			animation-delay: 0.32s;
		}
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
		}
	}
}
.lt-ie10, .ie10{
	.loader .wheel{
		background-image:url('/images/loading.gif');
		top: 0;
		box-shadow:0 0 0 0 transparent !important;	
		&:before, &:after{
			display:none;
		}
	}
}
@-webkit-keyframes wheel {
	0%,
	80%,
	100% {
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} var(--brand-color);
	}
	40% {
		box-shadow: 0 #{$loaderSize} 0 0 #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 0 var(--brand-color);
	}
}
@-moz-keyframes wheel {
	0%,
	80%,
	100% {
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} var(--brand-color);
	}
	40% {
		box-shadow: 0 #{$loaderSize} 0 0 #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 0 var(--brand-color);
	}
}
@-ms-keyframes wheel {
	0%,
	80%,
	100% {
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} var(--brand-color);
	}
	40% {
		box-shadow: 0 #{$loaderSize} 0 0 #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 0 var(--brand-color);
	}
}
@keyframes wheel {
	0%,
	80%,
	100% {
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 #{calc(#{-$loaderSize} / 2)} var(--brand-color);
	}
	40% {
		box-shadow: 0 #{$loaderSize} 0 0 #{$brandColor}; // IE fallback
		box-shadow: 0 #{$loaderSize} 0 0 var(--brand-color);
	}
}