.custom-title-heading {
	position: relative;
}

.custom-title-title {
	@include title();
	display:inline-block;
}

.custom-title-edit {
	display:inline-block;
}

.custom-title-edit input {
	width: 80%;
}
// todo: merge these classes with .add-eligibility-question and .edit-question classes
.custom-rule-icons-wrap{
	clear:both;
	display:inline-block;
	width:100%;
	overflow:hidden;
	position:absolute;
	bottom:100%;
	margin-bottom: #{$quarterMargin}; // IE fallback;
	margin-bottom: var(--quarter-margin);
	.custom-rule-icons{
		background: #{$brandColor}; // IE fallback
    	background: var(--brand-color);
		padding: #{$thinPadding} #{$halfPadding} #{$quarterPadding} #{$halfPadding}; // IE fallback
		padding: var(--thin-padding) var(--half-padding) var(--quarter-padding) var(--half-padding);
		float: right;
		display: inline-block;
		position: relative;
		button{
			color: #{$white};
			&:hover{
				background: #{$white};
				color: #{$brandColor}; // IE fallback
        color: var(--brand-color);
			}
		}
	}
}