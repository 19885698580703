.field-date{
	.field-date-display{
		color: inherit;
		display: inline-block;
	}
}
body{ // for specificity
	/* date picker overrides */
	.picker__nav--prev:hover,
	.picker__nav--next:hover,
	.picker__day--highlighted,
	.picker__day--infocus:hover,
	.picker__day--outfocus:hover,
	.picker__button--today:hover,
	.picker__button--clear:hover,
	.picker__button--today:focus,
	.picker__button--clear:focus {
		background: #{$highlightColor}; // IE fallback
		background: var(--highlight-color);
	}
	.picker__button--today:hover,
	.picker__button--clear:hover{
		border-bottom-color: #{$highlightColor}; // IE fallback
		border-bottom-color: var(--highlight-color);
	}
	.picker__select--month:focus,
	.picker__select--year:focus,
	.picker__day--selected,
	.picker__day--selected:hover {
		border-color: #{$brandColor}; // IE fallback
		border-color: var(--brand-color);
	}
	.picker__day--today{
		background: #{$backgroundColor}; // IE fallback
		background: var(--background-color);
		color: #{$brandColor}; // IE fallback
		color: var(--brand-color);
		border: 1px solid;
		border-color: #{$brandColor}; // IE fallback
		border-color: var(--brand-color);
	}
	.picker__day--highlighted:hover,
	.picker--focused .picker__day--highlighted{
		background: #{$backgroundColor}; // IE fallback
		background: var(--brand-color);
	}
	.picker__button--today:focus,
	.picker__button--clear:focus{
		border-color: #{$brandColor}; // IE fallback
		border-color: var(--brand-color);
	}
	.picker__weekday, .picker__year {
		color: #{$textAccentColor}; // IE fallback
		color: var(--text-accent-color);
	}
	.picker__day--outfocus{
		color: #{$textAccentColor}; // IE fallback
		color: var(--text-accent-color);
		background: #{$accentColor};
		background: var(--accent-color);
	}
	.picker__select--month,
	.picker__select--year{
		color: #{$textColor}; // IE fallback
		color: var(--text-color);
	}
}
