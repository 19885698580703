@media all and (max-width: 1000px) {
	body {
		.form{
			padding-right: var(--double-padding);
		}
		.layout-main-panel{
			position: relative;
		}
		#{$fieldValueItems} {
    	max-width: 90%;
		}
		.header{
			.brand{
				width: 7.25rem;
				min-width: 7.25rem;
			}
		}
		.layout{
			overflow: auto !important;
		}
		.layout-drawer{
			flex-basis: auto;
			flex: 0;
		}
		.layout-main-panel{
			flex: auto;
		}
		&.expanded-side-nav{
			.layout-drawer{
				flex-basis: auto;
				flex: auto;
				max-width: 75%;
			}
			.layout-main-panel{
				flex: 0;
			}
		}
		.btf-modal{
			max-width: none;
			max-height: 90vh;
			width: 90vw;
		}
		.page-title{
			padding-left: 3em;
		}
		.header{
			.site-nav{
				li {
			    max-width: 5em; // IE fallback
			    max-width: 20vw;
			    text-overflow: ellipsis;
			    overflow: hidden;
				}
			}
		}
	}
}
@media all and (max-width: 800px) {
	body{
		--thin-padding: 0rem;
		--quarter-padding: .1rem;
		--half-padding: .15rem;
		--full-padding: .25rem;
		--double-padding: .5rem;
		--huge-padding: .75rem;

		--quarter-margin: .1rem;
		--half-margin: .15rem;
		--full-margin: .25rem;
		--double-margin: .5rem;
		&.expanded-side-nav{
			.layout-drawer{
				max-width: 50%;
			}
		}
		.header .nav-links, .header .user-links{
			padding: 0;
		}
	}
}
@media all and (max-width: 600px) {
	body {
		.header{
			.user-header{
				display: none;
			}
			.settings{
				margin-top: .25em;
			}
		}
	}	
}