[tabs] {
	width: 100%;
}
.tabs{
	display: flex;
	flex-direction: column;
	.tabs-header {
		flex: 0;
		list-style-type: none;
		margin: 0;
		padding: #{$halfPadding} #{$fullPadding}; // IE fallback; 
		padding: var(--half-padding) var(--full-padding);
		font-size: #{$semiFontSize}; // IE fallback;
		font-size: var(--semi-font-size);
		li {
			display: inline-block;
			margin: 0;
			cursor: pointer;
			position: relative;
			border-bottom: 1px solid transparent;
			&.ng-invalid:before {
				left: 0;
				top:  0;
			}
			a {
				display: inline-block;
				color: inherit;
				text-decoration: none;
				padding: #{$halfPadding} #{$fullPadding}; // IE fallback; 
				padding: var(--half-padding) var(--full-padding);
			}
			&:hover {
				color: #{$brandAccentColor}; // IE-fallback;
				color: var(--brand-accent-color);
			}
			&.active{
				color: #{$brandColor}; // IE-fallback;
				color: var(--brand-color);
				border-bottom-color: #{$brandColor}; // IE-fallback;
				border-bottom-color: var(--brand-color);
			}
		}
	}
	.tab-content{
		position: relative;
		flex: 1;
		height: 100%;
    overflow: auto;
	}
}