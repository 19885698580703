.group{
	&.ng-invalid:before {
		content: '';
	}
	&:not([inline]) .field-wrapper{
		padding: 0;
		&.ng-invalid:before{
			left: -1.25em;
			top: 0.35rem;
		}
	}
	&[inline=true], &[inline='true']{
		& > .field-wrapper{
			display: inline;
			position: static;
			padding:0;
			
			.error-messages, [field-history]{
				display:none;
			}
	
			.collection .error-messages {
				display: block;
			} 

			&:first-child{
				margin-left:0;
			}
		}
		h4, h5, h6{
			display: inline-block;
			white-space: pre;
		}
	}
}