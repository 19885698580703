.module-usage{
	.checkbox{
		margin: #{$quarterPadding} #{$halfPadding};
		margin: var(--quarter-padding) var(--half-padding);
		vertical-align: middle;
	}
	.grouped{
		& > .title{
			@include bold();
		}
		border-bottom:1px solid;
		border-bottom-color: #{$accentColor}; // IE fallback
		border-bottom-color: var(--accent-color);
	}
}