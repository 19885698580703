input.switch{
	height: 0;
	width: 0;
	visibility: hidden;
	display: block;
	& + label.switch {
		position: relative;
		cursor: pointer;
		text-indent: -9999em;
		width: 2.5em; // intentionally ems
		height: 1.125em; // intentionally ems
		margin-top: -.11em; // intentionally ems
		background: $textAccentColor; // IE fallback
		background: var(--text-accent-color);
		display: inline-block;
		border-radius: 10rem;
		vertical-align: middle;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: .9em; // intentionally ems
			height: .9em; // intentionally ems
			margin: .1em; // intentionally ems
			background: $backgroundColor; // IE fallback
			background: var(--background-color);
			border-radius: 10rem;
			transition: left 0.3s ease-in-out, width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
		}
		&:active:after {
			width: 1.25rem;
		}
		&:before{
			content: 'off';
			position: absolute;
			display: inline-block;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 2;
			color: white;
			font-size: .75em; // intentionally not rem, variable
			text-indent: 0;
			line-height: #{$shortestLineHeight};
			vertical-align: middle;
			padding: .25em .5em; // intentionally not rem, variable
			box-sizing: border-box;
			text-align: right;
		}
	}
	&:checked + label.switch {
		background: $brandColor; // IE fallback
		background: var(--brand-color);
		&:after {
			left: 100%;
			margin-left: -1em; // intentionally ems for font size scaling
		}
		&:active:after {
			margin-left: -1.25rem;
		}
		&:before{
			content: 'on';
			text-align: left;
		}
	}
	& + label{
		cursor: pointer;
	}
}
