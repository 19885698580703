.setup-wizard{
	ul {
		list-style: none;
		padding: 0;
	}

	.valid-url{
		color: #{$successColor};
  	color: var(--success-color); // IE fallback
	}

	p.validation-message {
		margin-bottom: 0;
		color: #{$validationColor};
  	color: var(--validation-color); // IE fallback
  	&.green{
  		color: #{$successColor};
  		color: var(--success-color); // IE fallback
  	}
	}

	.field-wrapper.ng-invalid-min.ng-pristine .visited ~ .error-message {
		display:none;
	}
	.field-wrapper.ng-invalid.ng-invalid-number {
		.error-message-number {
			display:block;
		}
		.error-message-min {
		 	display:none;
		}
		.error-message-max {
		 	display:none;
		}
	}
}
