.field-dropdown {
	// !importants here to allow the dropdown color to be themeable
	// implemented with background-image because <select>s don't support pseudo elements https://stackoverflow.com/questions/3532649/problem-with-select-and-after-with-css-in-webkit
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAQCAYAAAD9L+QYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ1dWlkOjY1RTYzOTA2ODZDRjExREJBNkUyRDg4N0NFQUNCNDA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkIxN0JFMjg5RENDMzExRTJBQUE5RjRDNjg1MzQ5REZCIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkIxN0JFMjg4RENDMzExRTJBQUE5RjRDNjg1MzQ5REZCIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ2MTAzODVhLTBhYWEtNDgyYi1iY2I2LTIzM2YxMjEyZWI5YyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2Mjg5YjU2OS1kODVhLTQ2YzAtOWQyNC1lNDEwYTNiMGY2MGQiLz4gPGRjOnRpdGxlPiA8cmRmOkFsdD4gPHJkZjpsaSB4bWw6bGFuZz0ieC1kZWZhdWx0Ij5nbHlwaGljb25zX2hhbGZsaW5nc0AyeDwvcmRmOmxpPiA8L3JkZjpBbHQ+IDwvZGM6dGl0bGU+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Z3tmowAAAONJREFUeNqck0EOgjAQRYd6AA+gcDEXbolegBOAJ0C3LrwY8QTubf1DRjJBSqf+5Id26LzfNkAhBNIuy10L1/N6ytzDvbo2X9DBAfY5AQL20tt9645EVbXv8GhkWsA9ajUlJGt66WE1wqKCE2ZgLZyNTsPwvBnBWheHBbz7MrKx6AkSYNbWYVcegyP8sAYYwFf4PF6LNGzwuMOHSMN4RTJOgrHpMMEzAsgC5onTb1B8G67IBP6BGwNM4EX4HwGL4Cg8IyAKXoUbAlbBSfhKQBI8/f4Wqc/0ZQFnwVWAt4BZHwEGACuU6wpY7AJiAAAAAElFTkSuQmCC) !important;
	background-repeat: no-repeat !important;
	background-position: right #{$halfPadding} center !important;
	background-position: right var(--half-padding) center !important;
	background-size: .75rem .5rem !important;
	background-color: transparent;
	min-width: 2rem;
	/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=649849 */
	text-indent: 0.01px;
	text-overflow: '';
	vertical-align: bottom !important;
	&::-ms-expand {
	  display: none;
	}
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.field-dropdown-width {
	color: transparent;
	display:inline-block;
}