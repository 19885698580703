/* after we can use position sticky: 
	.table{
		position: relative;
		flex: 1;
		overflow: auto;
		.fixed {
			position: sticky;
			z-index: 2;
			top: 0;
		}
		.scroll thead tr th{
			display: none;
		}
	}
*/
.table{
	$tableHeaderHeight: 4em;
	position: relative;
	flex: 1;
	overflow: auto;
	.fixed {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		height: #{$tableHeaderHeight};
	}
	.scroll{
		overflow: auto;
		thead {
			position: sticky;
			top: 0px;
			z-index: 1;
		}
	}
	table{
		width:100%;
		tr, td, th{
			position:relative;
			text-align:left;
		}
		th, td{
			padding: #{$halfPadding} #{$halfPadding}; // IE fallback
			padding: var(--half-padding) var(--half-padding);
			&.orderable{
				cursor:pointer;
			}
			&.label{
				@include bold();
			}
			&.centered{
				text-align:center;
			}
		}
		thead{
			background: #{$highlightColor}; // IE fallback
			background: var(--highlight-color);
			td{
				border-color: #{$accentColor}; // IE fallback
				border-color: var(--accent-color);
			}
		}
		tbody tr{
			&.clickable{
				cursor: pointer;
				border-bottom: 1px solid;
				border-color: #{$darkGrey}; // IE fallback
				td {
					//text-decoration: underline;
					&.actions{
						text-decoration: none;
					}
				}
			}
			&:hover{
				background: #{$hoverColor}; // IE fallback
				background: var(--hover-color);
				td.actions{
					color: #{$textColor}; // IE fallback;
					color: var(--text-color);
				}
			}
			& + .show-when-empty{
				display:none;
			}
		}
		.actions{
			color: #{$textAccentColor}; // IE fallback;
			color: var(--text-accent-color);
			display: flex;
			justify-content: space-around;
			button {
				color: inherit;
				border: none;
				background: none;
				width: #{2*$iconSize};
				height: #{2*$iconSize};
				&.fa-trash {
					color: #{$errorColor}; // IE fallback;
					color: var(--error-color);
				}
				.fa-refresh {
					margin-right: #{$quarterPadding}; // IE fallback
					margin-right: var(--quarter-padding);
				}
				&.renew {
					color: white;
					display: flex;
					background: var(--brand-color);
					width: auto;
					height: auto;
					justify-content: center;
				}
			}
		}
	}
}
