.publishing{
	h2{
		display: inline-block;
    max-width: 90%;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-left: #{$quarterMargin};
    margin-left: var(--quarter-margin);
	}
	.publishing-controls{
		button {
			margin: #{$halfMargin}; // IE fallback;
	    margin: var(--half-margin);
		}
	}
	.product-nav-title{
		&::before {
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "";
			width:10px;
			color: #{$brandColor}; // IE fallback
			color: var(--brand-color);
			text-align:center;
			vertical-align: middle;
		}
	}
	.is-expanded {
		.product-nav-title {
			&::before {
				content: "";
			}
		}
	}
}

// for the GO url publishing section
div[section-name="Publishing"] {
	padding: 1em;

	// aligns everything to the left
	.field-wrapper .field-label > span > span {
		margin-left: 0;
	}

	//pushes the url input underneath the url field-label 
	.field-wrapper[field-name="benefits_counselor_url_path"] {
		display: block
	}
}
.enableBCMessage{
	color:red; 
	margin-left: 8px;
	font-size: 14px;
}