.bug-image{
	text-align: center;
	margin: #{$halfMargin} auto #{$fullMargin}; //ie fallback
	margin: var(--half-margin) auto var(--full-margin);

	img {
		max-width: 80%;
		display: inline-block;
	}
}
.error{
	display: inline-block;
	border: 2px solid;
	border-color: #{$errorColor}; // IE fallback;
	border-color: var(--error-color);
	.title{
		display: inline-block;
		width: 100%;
		padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
		padding: var(--quarter-padding) var(--half-padding);
		background: #{$errorColor}; // IE fallback;
		background: var(--error-color);
		font-size: #{$bigFontSize};
		font-size: var(--big-font-size);
		color:white;
	}
	.message{
		padding: #{$halfPadding}; // IE fallback
		padding: var(--half-padding);
	}
	a{
		color: #{$brandColor}; // IE fallback
    color: var(--brand-color);
	}
	.code{
		color: #{$brandColor}; // IE fallback
    color: var(--brand-color);
		@include bold();
	}
}

.error-box {
	.error-info {
		position: relative;
		max-width: 30rem;
		display: inline-block;
		background: #{$lightRed};
		border: 2px solid;
		border-color: #{$errorColor};
		border-color: var(--error-color);
		border-radius: #{$halfPadding}; // IE fallback
		border-radius: var(--half-padding);
		padding: #{$doublePadding}; // IE fallback
		padding: var(--huge-padding);
		color: #{$errorColor}; // IE fallback;
		color: var(--error-color);
		p {
			@include bold();
			font-size: #{$normalFontSize}; // IE fallback;
			font-size: var(--normal-font-size);
		}
	}
}
