.product-usage .product-nav{
	list-style: none;
	padding: 0;
	@include bold();
	.product-nav-title {
		background: #{$highlightColor}; // IE fallback
		background: var(--highlight-color);
		border-bottom: 1px solid #{$backgroundColor};
		border-bottom: 1px solid var(--background-color);
		cursor: pointer;
		padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
		padding: var(--quarter-padding) var(--half-padding);

		input {
			display: inline-block;
		}

		&::before {
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "";
			width:10px;
			color: #{$brandColor}; // IE fallback
			color: var(--brand-color);
			text-align:center;
		}
	}
	.is-expanded {
		.product-nav-title {
			&::before {
				content: "";
			}
		}
	}
	.table{
		padding-left: #{$fullPadding};
		padding-left: var(--full-padding);
		span{
			display: inline-block;
			margin: #{$quarterMargin};
			margin: var(--quarter-margin);
		}
		table{
			col:nth-child(1){
				 width:5%;
			}
			col:nth-child(2){
				 width:40%;
			}
			col:nth-child(3){
				 width:55%;
			}
			th {
	    	padding: #{$halfPadding};
	    	padding: var(--half-padding);
			}
			thead{
				background: $backgroundColor; // IE fallback;
				background: var(--background-color);
				font-style: italic;
				font-weight: normal;
				color: $textAccentColor; // IE fallback;
				color: var(--text-accent-color);
			}
		}
	}
	.product-nav-sub-nav {
		padding: 0 0 0 #{$fullPadding}; // IE fallback
		padding: 0 0 0 var(--full-padding);
		&[ng-repeat*="settings"] {
			padding-bottom: #{$halfPadding}; // IE fallback;
			padding-bottom: var(--half-padding);
			padding-left: 0;
		}
		.product-nav-item {
			padding: 0 3rem #{$halfPadding} #{$halfPadding}; // IE fallback
			padding: 0 3rem var(--half-padding) var(--half-padding);
			&[aria-expanded="true"] {
				+ .product-nav-sub-sub-item {
					overflow: visible;
				}
			}
		}
	}
	.product-nav-title{
		padding-left: #{$fullPlusPadding}; // IE Fallback
		padding-left: var(--full-plus-padding);
		padding-right: #{$doublePadding}; // IE Fallback
		padding-right: var(--double-padding);
	}
}
