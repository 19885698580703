.header {
	display: flex;
	align-items: center;
	z-index: 3;
	background: #{$brandColor}; // IE-fallback;
	background: var(--brand-color);
	color: #{$backgroundColor}; // IE fallback;
	color: var(--background-color);
	& > * {
		display: inline-block;
	}
	a{
		padding: 0 #{$halfPadding}; // IE fallback
		padding: 0 var(--half-padding);
		display: inline-block;
	}
	a, a:visited{
		color: inherit;
		text-decoration:none;
	}
	img{
		vertical-align: middle;
	}
	.site-nav {
		list-style: none;
		margin: 0;
		display: inline-block;
		color: inherit;
		line-height: 0;
		vertical-align: middle;
		li {
			display: inline-block;
			&.active {
				background: #{$brandAccentColor}; // IE-fallback;
				background: var(--brand-accent-color);
			}
		}
		> span, li, a{
			line-height: #{$hugeLineHeight};
		}
		.saving{
			line-height: 1;
		}
		#logout{
			text-decoration: underline;
			float: right; // IE hack - prevent unexplained padding from this button ¯\_(ツ)_/¯
		}
	}
	.brand, .user-links{
		flex: 0 1 auto;
	}
	.nav-links{
		flex: 1 1 auto; // IE doesn't like `flex: 1;` shorthand
		padding-left: #{$quarterPadding}; // ie
		padding-left: var(--quarter-padding);
	}
}

.welcome-banner {
	display: flex;
	border: 3px solid #{$validationColor}; //ie fallback
	border: 3px solid var(--validation-color);
	background: #{$backgroundColor}; // IE fallback;
	background: var(--background-color);

	.megaphone-icon-container {
		background-color: #{$validationColor};
		background-color: var(--validation-color);
	    padding: $halfPadding $fullPadding;
	    padding: var(--half-padding) var(--full-padding);
	    display: flex;
	    align-items: center;
	}

	a {
		color: #{$brandColor}; //ie
		color: var(--brand-color);
	}

	span {
		align-self: center;
		margin: 0 #{$fullMargin}; // IE fallback;
		margin: 0 var(--full-margin);
	}

	.dismiss-banner-container {
		position: absolute;
		right: 0px;
		margin-right: 10px;

		button {
			border: none;

			i {
				font-size: #{$bigFontSize}; //ie fallback
				font-size: var(--big-font-size);
				color: #{$brandColor}; //ie fallback
				color: var(--brand-color);
			}
		}
	}
}