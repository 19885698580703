/*
 * BRAND COLORS
 *
 * DO NOT CHANGE THESE!
 *
 */

 $alexBlue: #036991; // UI ALEX blue - for accessibility
 $alexLogoBlue: #0981b0;
 
 $red: #e00000;
 $orange: #ff8458;
 $yellow: #ffd831;
 $green: #3cbf00;
 $blue: #00b8e0;
 $cyan: #41D8AD;
 $purple: #8e0c70;
 $lightGray: #f2f2f2;
 $gray: #707070;
 $black: #333333;
 $white: #ffffff;
 $ink: #2f2f52;
 
 /*
  * END BRAND COLORS
  */
 
 $trueBlack: #000;
 $accentBlue: #2f2f52;
 $lightBlue: #def8fc;
 $lightRed: #f8d7da;
 $darkRed: #721c24;
 
 $color-text-dark: #333333;
 $color-error: #ff4d4f;
 $color-background-light: #f9f9f9;
 $color-text-muted: #666666;
 $color-border-light: #e0e0e0;