// adding this context here to distinguish the picker class from our directive
// from the picker class being used in the pickadate library
.field-wrapper {
  &.picker {
    z-index: auto;
    &.picker-active {
      z-index: 2;
    }
  }
}
.picker {
  font-size: #{$baseFontSize};
  color: #{$textColor}; // IE fallback
  color: var(--text-color);
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.field-picker {
  position: relative;
  display: inline-flex;
  ul {
    list-style: none;
    padding:0;
    margin:0;
    border: 1px solid;
    border-color: #{$brandColor}; // IE fallback);
    border-color: var(--brand-color);
    background: #{$backgroundColor}; // IE fallback
    background: var(--background-color);
    li {
      background: #{$backgroundColor}; // IE fallback
      background: var(--background-color);
      a {
        display: block;
        padding: #{$quarterPadding} #{$fullPadding}; // IE fallback
        padding: var(--quarter-padding) var(--full-padding);
        color: #{$textColor}; // IE fallback
        color: var(--text-color);
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        cursor: pointer;
      }
      &.divider {
        display: none;
      }
    }
    li:hover {
      background: #{$hoverColor}; // IE fallback
      background: var(--hover-color);
      width: 100%;
      height: 100%;
    }
  }
  .dropdown-menu {
    position: absolute;
    top:100%;
    left: -1px;
    margin: 0;
    z-index: 1;
  }
  .dropdown-multiselect {
    padding: 0;
    padding-right: 1rem; // intentionally not variable - fits dropdown arrow
    &:before {
      position: absolute;
      font: #{$fontAwesome};
      font-size: #{$iconFontSize};
      top: 50%;
      right: #{$quarterPadding};
      margin-top: #{calc(#{-$iconSize} / 2)};
      content: #{$fa-var-chevron-down};
    }
    &.open{
      border: 1px solid;
    }
    button{
      border: 0;
      vertical-align: baseline;
      background: transparent;
      &:hover{
        color: #{$brandColor}; // IE fallback
        color: var(--brand-color);
      }
    }
  }
  .dropdown-header { // search bar thingy
    display: block;
    padding: #{$quarterPadding} #{$fullPadding};
    padding: var(--quarter-padding) var(--full-padding);
    color: #{$textAccentColor};
    color: var(--text-accent-color);
    white-space: nowrap;
    input {
      width: auto !important; // overwrite library inline width;
      height: #{$inputHeight};
      padding: #{$halfPadding} #{$fullPadding};
      padding: var(--half-padding) var(--full-padding);
      background: #{$backgroundColor}; // IE fallback
      background: var(--background-color);
      color: #{$textColor}; // IE fallback
      color: var(--text-color);
      border: 1px solid;
      border-color: var(--accent-color);
      border-radius: .5rem;
      box-sizing: content-box;
      display: inline-block;
    }
  }
  .glyphicon {
    @include fa-icon();
    &.glyphicon-ok:before {
      content: $fa-var-check;
    }
    &.glyphicon-remove:before {
      content: $fa-var-times;
    }
  }
}
