.hello.hello-hydrate{
  background: $brandColor; // IE fallback
  background: var(--brand-color);
  color: $backgroundColor; // IE fallback
  color: var(--background-color);
  font-size: 4rem;
  .cup{
    display:block;
    position: relative;
    margin: 0 auto;
    height: 2.5em;
    width: 2em;
    background: $backgroundColor; // IE fallback
    background: var(--background-color);
    border-radius: .125em;
    box-shadow: .5em .5em 1em rgba(0,0,0,.25);
    &:before, &:after{
      content:'';
      position:absolute;
      z-index:-1;
    }
    &:before{
      top:.5em;
      bottom:.5em;
      width:2em;
      left:.5em;
      background: inherit;
      border-radius: .25em;
    }
    &:after{
      top:.7em;
      bottom:.7em;
      width:1.6em;
      left:.7em;
      background: $brandColor; // IE fallback;
      background: var(--brand-color);
      border-radius: .125em;
    }
  }
}