.login {
    width: 100%;
    height: 100%;
    max-width: 100%;
    .title {
        text-align:center;
    }
    .body {
        @include fillTo(0);
        top: 2.5em; // intentionally not a variable - leave space for header on short screens
        text-align: center;
        &:before {
            content: '';
            vertical-align: middle;
            height: 100%;
            display: inline-block;
            width: 1px;
            margin-right: -1px;
        }
        
    }
    .content {
        display: inline-block;
        vertical-align: middle;
    }
}
