/***************************************
  vAccordion
 ***************************************/


/**
 * Example HTML:
 *
  <v-accordion class="vAccordion--default">
    <v-pane>
      <v-pane-header id="pane-header" aria-controls="pane-content">
         [content]
      </v-pane-header>
      <v-pane-content id="pane-content" aria-labelledby="pane-header">
        [content]
      </v-pane-content>
    </v-pane>
  </v-accordion>
 */


@import 'settings';
@import 'base';
@import 'theme';

