// todo: merge css from TAILOR-862 into these "field panel" reusable classes
.field-panel{
	border: 2px solid;
	border-color: #{$brandColor}; // IE fallback
  border-color: var(--brand-color);
  margin: #{$halfMargin} 0; // IE fallback
  margin: var(--half-margin) 0;
  overflow: auto;
  max-height: 20rem;
  width: calc(100% + #{$fieldPaddingLeft});
  margin-left: #{-$fieldPaddingLeft};
  & > ul {
  	padding: 0;
  	list-style: none;
  	margin: 0;
  }
  & > ul > li{
  	margin: 0;
  }
  .field-panel-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid;
    border-color: #{$accentColor}; // IE fallback
    border-color: var(--accent-color);
  }
  .field-panel-title, .field-panel-content{
    padding: #{$halfPadding}; // IE fallback
    padding: var(--half-padding);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .field-panel-title {
    width: 15rem;
    max-width: 50%;
    flex: none;
    text-align: right;
    .title {
      font-weight: bold;
      word-break: break-all;
    }
    .subtitle {
	    font-size: #{$iconFontSize}; // IE fallback
	    font-size: var(--icon-font-size);
      font-style: italic;
    }
    .heading {
      font-size: #{$iconFontSize}; // IE fallback
      font-size: var(--icon-font-size);
      font-style: italic;
      text-transform: uppercase;
      color: $textAccentColor; // IE fallback
      color: var(--text-accent-color);
    }
  }
  .field-panel-content{
    min-width:10em;
    flex: 1;
    background: #{$highlightColor}; // IE fallback
    background: var(--highlight-color);
  }
}
