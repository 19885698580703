$barSize: .2em;
$speed: 2;
$triangleSide: 3 * sqrt(2) * $barSize * -1;
$color1: $highlightColor;
$color2: $brandAccentColor;
$color3: $brandAccentColor;
$color4: $highlightColor;
$borderSize: 1px;

.saving{
	border-radius: 1000rem;
	display:inline-block;
	vertical-align:middle;
	margin: auto #{$halfMargin};
	position:relative;
	overflow:hidden;
	border: #{$borderSize} solid #{$color1};
	opacity:1;
	color: #{$textColor};
	font-size: #{$iconFontSize};
	line-height: #{$shortestLineHeight};
	padding: #{$thinPadding} #{$halfPadding}; // IE fallback
	padding: var(--thin-padding) var(--half-padding);
	&:before, &:after{
		content: '';
		position: absolute;
		z-index: -1;
		vertical-align: middle;
	}
	&:before{
		top: -$borderSize;
		left: -$borderSize;
		right: -$borderSize;
		bottom: -$borderSize;
		font-size: #{$iconFontSize};
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: -100%;
		width: #{100%  * $speed};
		height:100%;
		background-image: repeating-linear-gradient(
			45deg,
		 #{$color1} 0,
		 #{$color2} 0,
		 #{$color3} #{$barSize * 1},
		 #{$color1} #{$barSize * 1},
		 #{$color1} #{$barSize * 2},
		 #{$color4} #{$barSize * 2},
		 #{$color3} #{$barSize * 3}
		) !important;
		animation-fill-mode: both;
		animation: slide 1.8s infinite linear;
	}
	&.saving-done{
		opacity:0;
		-webkit-transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-ms-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
	}
}
@-webkit-keyframes slide {
	0%{
		left: #{$speed * $triangleSide};
	}
	100%{
		left:0%;
	}
}
@-moz-keyframes slide {
	0%{
		left: #{$speed * $triangleSide};
	}
	100%{
		left:0%;
	}
}
@-ms-keyframes slide {
	0%{
		left: #{$speed * $triangleSide};
	}
	100%{
		left:0%;
	}
}
@keyframes slide {
	0%{
		left: #{$speed * $triangleSide};
	}
	100%{
		left:0%;
	}
}
