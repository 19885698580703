.aside {
	display: inline-block;
	&:empty {
		display: none;
	}
	a {
		cursor: pointer;
		color: #{$brandColor}; // IE fallback;
		color: var(--brand-color);

		&:hover {
			text-decoration: underline;
		}
	}
	.sidebar & {
		> div {
			display: block;
		}
		a {
			display: none;
		}
	}
}
.help-help-text {
	margin-top: #{$halfMargin}; // IE fallback;
	margin-top: var(--half-margin);
	position: relative;
	display: none;
}

.eligibility-question:hover .aside {
	display: block;
}
.eligibility-question .aside {
	left: 100%;
	display: block;
}
.form .aside.has-help-text {

	&:before{
        cursor: pointer;
		content: '?';
		@include xbold();
		color: #{$brandColor}; // IE fallback;
		color: var(--brand-color);
		border-radius: 2em;
		border: 2px solid #{$brandColor}; // IE fallback
		border: 2px solid var(--brand-color);
		background-color: #{$backgroundColor}; // IE fallback;
		background-color: var(--background-color);
		display: inline-block;
		font-size: #{$semiFontSize}; // IE fallback;
		font-size: var(--semi-font-size);
		margin-left: #{$halfMargin}; // IE fallback;
		margin-left: var(--half-margin);
		padding: 0 #{$quarterPadding}; // IE fallback
		padding: 0 var(--quarter-padding);
		line-height: 1;
	}
	&.showing {
		&:before {
            cursor: pointer;
			background: #{$brandColor}; // IE fallback;
			background: var(--brand-color);
			color: #{$backgroundColor}; // IE fallback;
			color: var(--background-color);
		}
		> div {
			display: block;
			background: #{$backgroundColor}; //IE fallback
			background: var(--background-color);
			border: 2px solid #{$brandColor}; //IE fallback
			border: 2px solid var(--brand-color);
			box-shadow: $defaultShadow;
			position: absolute;
			right: 0;
			left: 6em;
			top: 1.75em;
			width: auto;
			padding: #{$halfPadding}; // IE fallback;
			padding: var(--half-padding);
			z-index: 2;

		}
	}
	.external-field {
		font-family: monospace;
	}
	&:empty {
		display: none !important;
	}
}

.form .field-wrapper.label .aside.has-help-text {
	&.showing {
		> div {
			top: 3.75em;
		}
	}
}
