html, body {
	@include fillTo(0);
	margin: 0;
	padding: 0;
	overflow: hidden;
}
body{
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	align-items: center;
	.header{
		position: relative;
		flex: 0 0 auto;
		z-index: 2;
		width: 100%;
	}
	.container{
		flex: 1;
		content: strict;
		width: 100%;
		overflow: auto;
		& > *{
			display:flex;
	    height: 100%;
	    width: 100%;
	    overflow: auto;
		}
	}
}