.field-toggle {
	border-bottom: 4px double;
	margin-bottom: -2px;
	border-bottom-color: #{$brandColor};
	border-bottom-color: var(--brand-color);
	&:hover{
		text-decoration: none;
		outline: none;
		&:before, &:after{
			display: block;
			position: absolute;
			white-space: nowrap;
			z-index: 2;
			display: block;
			color: #{$white};
		}
		&:before{
			content: "";
			top: 0;
			left: 20%;
			height: #{$arrowSize};
			border: solid;
			border-color: #{$brandColor} transparent; // IE-fallback;
			border-color: var(--brand-color) transparent;
			border-width: #{$arrowSize} #{$arrowSize} 0 #{$arrowSize};
		}
		&:after {
			content: attr(data-tooltip);
			bottom: 100%;
			left: -0.25em; // intentionally not variable, ems, not rems
			border-radius: .5rem; // intentionally not variable
			background: #{$brandColor}; // IE-fallback;
			background: var(--brand-color);
			padding: #{$quarterPadding} #{$halfPadding}; // IE fallback
			padding: var(--quarter-padding) var(--half-padding);
		}
	}
}
